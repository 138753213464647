/* eslint-disable prettier/prettier */
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./CardWithStat.css";
import { ICard } from "../../../../@types/card.interface";

const useStyles = makeStyles({
	root: {
		width: 250,
		borderRadius: 10
	},
	pos: {
		marginBottom: 12
	}
});

const CardWithStat = ({
	title,
	data,
	icon,
	backgrondColor,
	iconBgColor,
	leftBottomBorderradius,
	leftTopBorderradius,
	righBottomBorderradius,
	rightTopBorderradius,
	width
}: ICard) => {
	const classes = useStyles();
	return (
		<Card
			className={classes.root}
			variant="outlined"
			style={{
				backgroundColor: backgrondColor,
				borderTopLeftRadius: leftTopBorderradius,
				borderTopRightRadius: rightTopBorderradius,
				borderBottomLeftRadius: leftBottomBorderradius,
				borderBottomRightRadius: righBottomBorderradius,
				width: width || 250
			}}
		>
			<CardContent>
				<div className="iconsInfoConatiner">
					<div className="icon" style={{ backgroundColor: iconBgColor }}>
						{icon}
					</div>
					<div className="statWithIcon">
						<h2 className="data">{data}</h2>
						<p>{title} </p>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};
export default CardWithStat;
