import React, { useCallback, useContext, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./components/pages/auth/Login";
import SignUp from "./components/pages/signup/Signup";
import ForgetUsername from "./components/pages/auth/forgetUsername/ForgetUsername";
import ForgetPassword from "./components/pages/auth/forgetPassword/ForgetPassword";
import ResetPassword from "./components/pages/auth/forgetPassword/ResetPassword";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Sidebar from "./components/shared/sidebar/Sidebar";
import AddGroup from "./components/pages/groups/addGroup/AddGroup";
import Group from "./components/pages/groups/group/Group";
import AddStore from "./components/pages/stores/addStore/AddStore";
import Store from "./components/pages/stores/Store";
import Devices from "./components/pages/devices/Devices";
import {
	DeviceManagement,
	SuperAdminSidebar,
	Groups,
	Customers,
	AdminSidebar,
	GroupCustomers,
	RegisteredCustomerScanDetails,
	CustomerDetails
} from "./components/pages";
import { AuthContext, AuthContextProvider, UIContextProvider } from "./contexts";
import SuperAdminDashboard from "./components/pages/superAdmin/dashboard/SuperAdminDashboard";
import AdminDashboard from "./components/pages/admin/dashboard/AdminDashboard";
import CustomerScanDetails from "./components/pages/admin/customers/customerDetails/customerScanDetails/CustomerScanDetails";
import PermissionManagement from "./components/pages/superAdmin/settings/permissionManagement/PermissionManagement";
import UserManagement from "./components/pages/superAdmin/settings/userManagement/UserManagement";
import AssignGroups from "./components/pages/superAdmin/assignGroups/AssignGroups";
import AddDevice from "./components/pages/superAdmin/settings/deviceManagement/addDevice/AddDevice";
import ProductManagement from "./components/pages/superAdmin/settings/productManagement/ProductManagement";
import AddProduct from "./components/pages/superAdmin/settings/productManagement/addProduct/AddProduct";
import { User } from "./@types/user.types";
import { trackPromise } from "react-promise-tracker";
import { api } from "./utils/api";
import DiseaseManagement from "./components/pages/superAdmin/settings/diseaseManagement/DiseaseManagement";
import AddDisease from "./components/pages/superAdmin/settings/diseaseManagement/diseaseManagementGrid/addDisease/AddDisease";
import AddUser from "./components/pages/superAdmin/settings/userManagement/addUserManagement/AddUserManagement";
import AdminStores from "./components/pages/admin/adminStores/AdminStores";
import Reports from "./components/pages/admin/customers/reports/Reports";
import SendText from "./components/pages/admin/sendText/SendText";

function App() {
	const userId = localStorage.getItem("@user");
	const { setUser } = useContext(AuthContext);

	const handlePageRefresh = useCallback(async () => {
		if (userId) {
			const response = await trackPromise(api.groupOwner.getGroupOwnerDetails({ _id: userId }));
			if (response) {
				setUser(response);
			}
		}
	}, [setUser, userId]);

	useEffect(() => {
		handlePageRefresh();
	}, [handlePageRefresh]);
	return (
		<div className="App">
			<BrowserRouter>
				<UIContextProvider>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/sign-up" element={<SignUp />} />
						<Route path="/reports" element={<Reports />} />
						<Route path="/forget-password" element={<ForgetPassword />} />
						<Route path="/forget-userid" element={<ForgetUsername />} />
						<Route path="/forget-password/verify-token/:jwt" element={<ResetPassword />} />
						{/* <Route
								path="/"
								element={
									localStorage.getItem("@jwt") && localStorage.getItem("@jwt") != "undefined" ? (
										<Navigate to="/groups"></Navigate>
									) : (
										<Navigate to={"/sign-up"}></Navigate>
									)
								}
							></Route> */}
						<Route element={<SuperAdminSidebar />}>
							<Route
								path="/"
								element={userId ? <SuperAdminDashboard /> : <Navigate to="/login" replace />}
							/>
							<Route path="/groups" element={<Groups />} />
							<Route path="/reports" element={<Reports />} />
							<Route path="/customers" element={<Customers />} />
							<Route path="/settings/user-management" element={<UserManagement />} />
							<Route path="/settings/devices" element={<DeviceManagement />} />
							<Route path="/settings/products" element={<ProductManagement />} />
							<Route path="/settings/disease" element={<DiseaseManagement />} />
							<Route path="/settings/add-disease" element={<AddDisease mode="add" />} />
							<Route path="/settings/add-product" element={<AddProduct mode="add" />} />
							<Route path="/settings/edit-product/:id" element={<AddProduct mode="edit" />} />
							<Route path="/settings/permission-management" element={<PermissionManagement />} />
							<Route path="/settings/group-permission" element={<AssignGroups />} />
							<Route path="/settings/add-device" element={<AddDevice mode="add" />} />
							<Route path="/settings/add-user" element={<AddUser mode="add" />} />
							<Route path="/settings/edit-device/:device_id" element={<AddDevice mode="edit" />} />

							<Route path="/add-group" element={<AddGroup />} />
							<Route path="/group" element={<Group />} />
							<Route path="/add-store" element={<AddStore />} />
							<Route path="/store" element={<Store />} />
						</Route>
						<Route element={<AdminSidebar />}>
							<Route path="/admin" element={<AdminDashboard />} />
							<Route path="/admin/customers" element={<GroupCustomers />} />
							<Route path="/admin/permission-management" element={<UserManagement />} />
							<Route path="/admin/reports" element={<Reports />} />
							<Route path="/admin/user-management" element={<UserManagement />} />
							<Route path="/admin/device" element={<DeviceManagement />} />
							<Route path="/admin/products" element={<ProductManagement />} />
							<Route path="/admin/disease" element={<DiseaseManagement />} />
							<Route path="/admin/stores" element={<AdminStores />} />
							<Route path="/admin/stores/add-store" element={<AddStore />} />
							<Route path="/admin/send-text" element={<SendText />} />
							<Route
								path="/admin/registered-customer-scan-details"
								element={<RegisteredCustomerScanDetails />}
							/>
							<Route path="/admin/customer-details" element={<CustomerDetails />} />

							{/* Dummy Routes */}
							<Route
								path="/admin/customer-scan-details"
								element={<CustomerScanDetails state="ROUTE" key={1} />}
							/>
						</Route>
					</Routes>
				</UIContextProvider>
			</BrowserRouter>
			{/* <SignUp />
			<Login />
			<ForgetUsername />
			<ForgetPassword />
			<ResetPassword /> */}
		</div>
	);
}

export default App;
