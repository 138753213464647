import React from "react";
import CustomerScanDetails from "../customerDetails/customerScanDetails/CustomerScanDetails";
import { CompanySampleLogo, DecathlonLogoBgremove, Logo } from "../../../../../assets";

const Reports = () => {
	return (
		<div className="report" style={{ padding: "20px" }}>
			<div className="green-border">
				<div
					className="inline-flex"
					style={{ justifyContent: "center", marginBottom: "5px", marginTop: "-10px" }}
				>
					{/* <img src={DecathlonLogoBgremove} style={{ width: 200 }} /> */}
					<img src={CompanySampleLogo} style={{ width: 200 }} />
				</div>
				<CustomerScanDetails state="REPORT" key={0} />
				<div className="inline-flex" style={{ justifyContent: "center", marginTop: "5px" }}>
					<div className="powered-by-container">Powered by: </div>
					<img src={Logo} style={{ width: 200 }} />
				</div>
			</div>
		</div>
	);
};

export default Reports;
