import { FormControl, Grid, InputLabel } from "@material-ui/core";
import { FC, useCallback, useState } from "react";
import ThemeTextField from "../../../shared/theme/textField/ThemeTextField";
import ActionButton from "../../../shared/actionButton/ActionButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const labels = {
	group_name: "Group Name",
	city: "City",
	address_line_1: "Address Line 1",
	address_line_2: "Address Line 2",
	state_code: "State Code",
	pincode: "Pincode",
	contact_first_name: "Contact First Name",
	contact_last_name: "Contact First Last",
	contact_phone_number: "Contact Phone Number",
	district: "District",
	GST_number: "GST Number",
	PAN: "PAN"
};

const AddGroup: FC = (): JSX.Element => {
	const [group, setGroup] = useState({
		group_name: "",
		city: "",
		address_line_1: "",
		address_line_2: "",
		state_code: "",
		pincode: "",
		contact_first_name: "",
		contact_last_name: "",
		contact_phone_number: "",
		district: "",
		GST_number: "",
		PAN: ""
	});
	const navigate = useNavigate();

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			setGroup(
				Object.assign({}, group, {
					[name]: value
				})
			);
		},
		[group]
	);

	const handleSubmitGroup = useCallback(async () => {
		try {
			const response = await axios.post("http://localhost:4008/api/v1/groups/add-group", group, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("@jwt")}`
				}
			});
			if (response) {
				alert("Succesfully added group");
				navigate("/groups");
			}
		} catch (err) {
			alert("Unable to add group");
		}
	}, [group]);

	return (
		<div className="add-group">
			<ActionButton onClick={handleSubmitGroup}>
				<div>Add Group</div>
			</ActionButton>
			{Object.entries(group).map(([name, value], index) => {
				return (
					<>
						<FormControl className="fullwidth" key={index}>
							<Grid container className="form-grid">
								<Grid item xl={2} lg={2} md={2} sm={6} xs={2} className="form-grid-label">
									<InputLabel
										shrink
										htmlFor="bootstrap-input"
										className="theme-input-label form-grid-label"
									>
										{labels[name as keyof typeof labels] + ":"}
									</InputLabel>
								</Grid>
								<Grid item xl={10} lg={10} md={10} sm={6} xs={6} className="form-grid-field">
									<ThemeTextField
										name={name}
										value={value}
										onChange={handleChange}
										style={{ width: 400 }}
									/>
								</Grid>
							</Grid>
						</FormControl>
						<div className="theme-section-margin" />
					</>
				);
			})}
			<ActionButton onClick={handleSubmitGroup}>
				<div>Add Group</div>
			</ActionButton>
		</div>
	);
};

export default AddGroup;
