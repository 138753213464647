import React from "react";

import BarChart from "../../../../shared/charts/barChart/BarChart";
import "./ScanDetails.css";
import { FormControl, FormHelperText, NativeSelect, Theme, createStyles, makeStyles } from "@material-ui/core";

type CardUIProps = {
	cardColor: string;
	cardTitle: string;
	overallTotal: string;
	marginTopText?: string;
};

type Datasets = {
	data: number[];
	backgroundColor: string[];
	borderColor: string[];
	borderWidth: number;
};

type ChartProps = {
	data: {
		labels: string[];
		datasets: Datasets[];
	};
	options: any;
};

type BenifitSummaryEnrollmentCard = {
	cardUIProps: CardUIProps;
	chartProps: ChartProps;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			minWidth: 120
		}
	})
);

const ScanDetails: React.FC<BenifitSummaryEnrollmentCard> = ({ cardUIProps, chartProps }): JSX.Element => {
	const classes = useStyles();
	const { cardTitle, cardColor, overallTotal, marginTopText } = cardUIProps;
	const { data, options } = chartProps;
	const [state, setState] = React.useState<{ age: string | number; name: string }>({
		age: "",
		name: "hai"
	});

	const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const name = event.target.name as keyof typeof state;
		setState({
			...state,
			[name]: event.target.value
		});
	};

	return (
		<div className="super-admin-scan-details" style={{ backgroundColor: `${cardColor}` }}>
			<div className="titleWithSelect">
				<div className="element-section-title">{cardTitle}</div>
				<FormControl className={classes.formControl}>
					<NativeSelect
						value={state.age}
						onChange={handleChange}
						name="age"
						inputProps={{ "aria-label": "age" }}
					>
						<option value={1}>Bata</option>
						<option value={2}>Khadims</option>
					</NativeSelect>
				</FormControl>
			</div>
			<div className="element-section-value" style={marginTopText ? { marginTop: `${marginTopText}` } : {}}>
				{overallTotal}
			</div>
			<div className="element-section-bar">
				{chartProps ? <BarChart data={data as any} options={options} /> : <></>}
			</div>
		</div>
	);
};

export default ScanDetails;
