import { AUTHORIZATION } from "../../../constants/api/auth";
import { headers } from "../../../config/config";
import { request } from "../api";
import { MESSAGE } from "../../../constants/api/message";

const { post, get, patch, del, put } = request;
const { Authorization, Bearer } = AUTHORIZATION;

const initialRoute = "/diseases";
export const getAllDiseaseList = async () => {
	try {
		const endpoint = `${initialRoute}/get-all-diseases`;
		const token = localStorage.getItem("@jwt");
		const response = await get(endpoint, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};
export const addDisease = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/add-disease`;
		const token = localStorage.getItem("@jwt");
		const response = await post(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.post.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};
