import { Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import StoreGrid from "./storeGrid/StoreGrid";
import UIContext from "../../../../contexts/UIContext/UIContext";
import { ADMIN_DASHBOARD_HEADER } from "../../../../constants/caption/dashboardHeader";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../../hooks";

const AdminStores = () => {
	const query = useQuery();
	const [groupNumber, setGroupNumber] = useState<number | null>(null);
	const { setDashboardHeader } = useContext(UIContext);
	const navigate = useNavigate();
	const handleAddStoreClick = () => {
		navigate(`/admin/stores/add-store?gn=${groupNumber}`);
	};
	useEffect(() => {
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.stores);
	}, [setDashboardHeader]);

	useEffect(() => {
		if (query && query.get("gn")) {
			setGroupNumber(Number(query.get("gn")));
		}
	}, [query]);
	return (
		<div>
			<div className="pf-action-button-container" id="pf-action-button-container">
				<Button
					className="button-green"
					variant="contained"
					onClick={handleAddStoreClick}
					style={{
						cursor: "pointer",
						pointerEvents: "unset"
					}}
				>
					<span className="button-label-with-icon">Add Stores</span>
					<span>{/* <DevicesIcon className="button-icon" /> */}</span>
				</Button>
			</div>
			<StoreGrid />
		</div>
	);
};

export default AdminStores;
