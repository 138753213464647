/* eslint-disable arrow-parens */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { TextField, Paper, Grid, Button, Input, Typography, makeStyles, IconButton } from "@material-ui/core";
import "date-fns";
import LoupeIcon from "@material-ui/icons/Loupe";
import { PRODUCT_FILED_LIST } from "../../../../../../constants/productFiledList/ProductFieldList";
import { capitalizeField } from "../../../../../../utils/commonFunctions/capitalizeField";
import { useCallback, useEffect, useState } from "react";
import { IProduct } from "../../../../../../@types/product.types";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../../../../../utils/api";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./addProduct.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductImageModal from "../productGrid/productImg.modal/ProductImg.modal";
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	heading: {
		marginBottom: theme.spacing(1),
		fontSize: theme.typography.subtitle1.fontSize,
		paddingRight: theme.spacing(2),
		display: "flex",
		alignItems: "center"
	},
	smallTextField: {
		width: "80%"
	}
}));

const AddProduct = ({ mode }: { mode: string }) => {
	const classes = useStyles();
	const [selectedItem, setSelectedItem] = useState(false);
	const [isDetailsFetched, setIsDetailsFetched] = useState(false);
	const [productPayload, setProductPayloadPayload] = useState<IProduct>({
		product_name: "",
		product_area: "",
		description: "",
		product_image: "",
		product_category: ""
	});
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = useParams();
	const handleSubmit = async () => {
		try {
			if (isDetailsFetched) {
				const result = await trackPromise(api.product.editProductById(id!, productPayload));
				console.log("data", id);
			} else {
				const result = await trackPromise(api.product.addProduct(productPayload));
				console.log("data", result);
			}
			navigate("/settings/products");
		} catch (error) {
			console.error("error", error);
		}
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = event.target;
		if (name === "pain_point") {
			setProductPayloadPayload(Object.assign({}, productPayload, { pain_point: Number(value) }));
		}
		setProductPayloadPayload(Object.assign({}, productPayload, { [name]: value }));
	};

	const convertImageToUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
		const reader = new FileReader();
		if (e.target.files![0]) {
			reader.readAsDataURL(e.target.files![0]);
			setSelectedItem(true);
		}
		reader.onload = async (readerEvent: ProgressEvent<FileReader>) => {
			const url = readerEvent.target?.result;
			setProductPayloadPayload(Object.assign({}, productPayload, { product_image: url }));
		};
	};

	const fetchData = useCallback(async () => {
		try {
			if (id) {
				const result: any = await trackPromise(api.product.getProductById(id));
				setProductPayloadPayload(result.data);
				setIsDetailsFetched(true);
				console.log(result.data);
			} else {
				console.warn("ID is undefined");
			}
		} catch (error) {
			console.error("error", error);
		}
	}, [id]);

	useEffect(() => {
		// console.log(id);
		fetchData();
	}, [fetchData]);

	return (
		<div className="create-createGroupOwner" id="create-createGroupOwner">
			{isDetailsFetched ? null : (
				<>
					<div className="pf-action-button-container" id="pf-action-button-container">
						<Button
							className="button-green"
							variant="contained"
							onClick={handleSubmit}
							style={{
								cursor: "pointer",
								pointerEvents: "unset"
							}}
						>
							<span className="button-label-with-icon">Add Product</span>
							<span>
								<LoupeIcon className="button-icon" />
							</span>
						</Button>
					</div>
				</>
			)}

			<Grid container spacing={5} className="pf-grid-container">
				<div>
					<Paper className="pf-paper-container" elevation={3} variant="outlined">
						<div>
							<div className={classes.root}>
								{PRODUCT_FILED_LIST.map((field, index) => (
									<Grid container spacing={3} key={index}>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<Typography variant="h6" className={classes.heading}>
												{capitalizeField(field)}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											{field === "product_image" ? (
												<>
													<label htmlFor="file">
														<div className="upload_Img_Btn">
															<span className="tooltip">select product image </span>
															<span>
																{selectedItem ? (
																	<>change image</>
																) : (
																	<>
																		{isDetailsFetched ? (
																			<IconButton>
																				<ProductImageModal
																					product_image={
																						productPayload.product_image
																					}
																					product_name={""}
																					product_area={""}
																					description={""}
																					diagnosis_id={""}
																					product_category={""}
																					arc_type={""}
																					pain_point=""
																				/>
																			</IconButton>
																		) : (
																			<>Upload</>
																		)}
																	</>
																)}
															</span>
														</div>
														{/* <p>*image must be under 1mb</p> */}
													</label>
													<input
														id="file"
														type="file"
														hidden
														onChange={(e) => convertImageToUrl(e)}
													/>
												</>
											) : (
												<TextField
													id={`product-${field}`}
													label={capitalizeField(field)}
													fullWidth
													variant="outlined"
													InputProps={{
														readOnly: mode === "edit"
													}}
													className={classes.smallTextField}
													name={field}
													value={String(productPayload[field])}
													onChange={handleInputChange}
												/>
											)}
										</Grid>
									</Grid>
								))}
							</div>
						</div>
					</Paper>
					{/* <div className="add_productBtn">
						<Button
							className="button-green "
							variant="contained"
							style={{
								cursor: "pointer",
								pointerEvents: "unset",
							}}
							onClick={handleSubmit}
						>
							<span className="button-label-with-icon">{
								(isDetailsFetched) ? <>Edit Product</> : <>Add Product</>
							}</span>
						</Button>
					</div> */}
				</div>
			</Grid>
		</div>
	);
};

export default AddProduct;
