/* eslint-disable */

import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import "./customFloatingFilter.css";

export default forwardRef((props, ref) => {
	const inputRef = useRef(null);
	useImperativeHandle(ref, () => {
		return {
			onParentModelChanged(parentModel) {
				inputRef.current.value = parentModel;
			}
		};
	});

	const onInputBoxChanged = useDebouncedCallback(async (input) => {
		props.parentFilterInstance((instance) => {
			setTimeout(() => {
				instance?.componentInstance?.myMethodForTakingValueFromFloatingFilter(input.target.value);
			}, 100);
		});
	}, 1000);

	const style = {
		color: props.color
	};

	return (
		<>
			<input
				ref={inputRef}
				className="customFloatingFilter"
				style={style}
				type="text"
				onInput={onInputBoxChanged}
			/>
		</>
	);
});
