/* eslint-disable */

import { set } from "date-fns";
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import "./customFilter.css";
import FILTER_FUNCTIONS from "../../../constants/filterFunctions/filterFunctions.json";

export default forwardRef((props, ref) => {
	const [filterText, setFilterText] = useState(null);
	const [filterType, setFilterType] = useState(FILTER_FUNCTIONS.starts_with);
	const inputRef = useRef(null);
	const selectRef = useRef(null);
	const { schema, setPayloadSchema, functionToBePassed, setParentSchema } =
		props.frameworkComponentWrapper.agGridReact.props.frameworkComponents;

	useImperativeHandle(ref, () => {
		return {
			getModel() {
				return filterText;
			},

			setModel(model) {
				setFilterText(model);
			},

			myMethodForTakingValueFromFloatingFilter(value) {
				setFilterText(value);
			}
		};
	});

	const onInputBoxChanged = useDebouncedCallback(async (event) => {
		if (event.target.value) setFilterText(event.target.value);
		else setFilterText("");
	}, 3000);

	const onSelectChanged = (event) => {
		setFilterType(event.target.value);
	};

	useEffect(() => {
		if (filterText === null || props.column.colDef.field === null || filterType === null) {
		} else {
			if (inputRef.current) {
				inputRef.current.value = filterText;
			}
			if (selectRef.current) {
				selectRef.current.value = filterType;
			}
			props.filterChangedCallback();
			//console.log("Data: ", props.column.colDef.field, filterText, filterType);
			setPayloadSchema({
				...schema,
				[props.column.colDef.field]: { value: filterText, type: filterType }
			});
			//functionToBePassed();
		}
	}, [filterText, filterType, functionToBePassed, props, schema, setPayloadSchema]);

	return (
		<div className="custom-ag-icon">
			<div style={{ padding: "4px" }} className="customFilter">
				<div className="filter-container">
					<select
						className="customFilterInput"
						ref={selectRef}
						style={{ margin: "4px 0 4px 0", padding: "0px" }}
						onInput={onSelectChanged}
					>
						{/* <option value={FILTER_FUNCTIONS.contains}>Contains</option>
					<option value={FILTER_FUNCTIONS.not_contains}>Not contains</option>
					<option value={FILTER_FUNCTIONS.equals}>Equals</option>
					<option value={FILTER_FUNCTIONS.not_equals}>Not equals</option> */}
						<option value={FILTER_FUNCTIONS.starts_with} selected>
							Starts with
						</option>
						{/* <option value={FILTER_FUNCTIONS.ends_with}>Ends with</option> */}
					</select>
					<b></b>
					<input
						className="customFilterInput"
						ref={inputRef}
						style={{ margin: "4px 0 4px 0" }}
						onInput={onInputBoxChanged}
						placeholder="Filter..."
					/>
				</div>
			</div>
		</div>
	);
});
