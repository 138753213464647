import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import ProductGrid from "../../../superAdmin/settings/productManagement/productGrid/ProductGrid";
import { PaginationQuery, PaginationTypes } from "../../../../../@types/pagination.types";
import { CellValueChangedEvent, ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../../../../utils/api";
import { IProduct } from "../../../../../@types/product.types";
import { IStoreType } from "../../../../../@types/store.types";
import { GroupBranding } from "../../../../../@types/group.types";
import getAgGridHeight from "../../../../../utils/commonFunctions/getAgGridHeight";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AuthContext } from "../../../../../contexts";
import ROLES from "../../../../../constants/roles";
import CustomPagination from "../../../../shared/customPagination/CustomPagination1";

const StoreGrid = () => {
	const { user } = useContext(AuthContext);
	const [stores, setStores] = useState<IStoreType[]>([]);
	const [gridHeight, setGridHeight] = useState<string>("400px");
	const [groupBranding, setGroupBranding] = useState<GroupBranding>({
		group_number: 220,
		logo: {},
		favicon: {}
	});
	const [groupsFilterSchema, setGroupsFilterSchema] = useState({
		name: { value: null, type: null },
		group_number: { value: null, type: null },
		email: { value: null, type: null }
	});
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	// eslint-disable-next-line no-unused-vars
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);
	const [limit, setLimit] = useState<number | null>(null);
	const [pagination, setPagination] = useState<PaginationTypes>({
		currentPage: 1,
		pageCount: undefined
	});
	const { currentPage, pageCount } = pagination;

	// const _limit = getLimit(xl, lg, md, sm, xs);
	const handleAgGridReady = (params: GridReadyEvent) => {
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};
	const getPaginatedProductList = useCallback(async () => {
		if (typeof limit === "number" && typeof pagination.currentPage === "number") {
			const paginationQuery: PaginationQuery = {
				limit: limit,
				page: pagination.currentPage
			};
			const storeList = await trackPromise(api.stores.getStoreList(paginationQuery));
			console.log("------>stores", storeList.groups);
			setStores(storeList.groups);
		}
	}, [limit, pagination.currentPage]);

	const handlePageChange = useCallback(
		(event: React.ChangeEvent<unknown>, page: number) => {
			setPagination(
				Object.assign({}, pagination, {
					currentPage: page
				})
			);
		},
		[pagination]
	);

	useEffect(() => {
		const _gridHeight = getAgGridHeight(stores.length);
		setGridHeight(_gridHeight);
	}, [stores.length]);

	useEffect(() => {
		setLimit(5);
		getPaginatedProductList();
	}, [getPaginatedProductList]);

	return (
		<div>
			<div className="all-customer-page-container">
				<div className="all-customers-grid-container">
					<div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
						<AgGridReact
							ref={agGridRef}
							rowData={stores}
							onGridReady={handleAgGridReady}
							onFirstDataRendered={handleFirstDataRendered}
							rowSelection={"Single"}
							animateRows
							suppressColumnVirtualisation={true}
							defaultColDef={{
								filter: "agTextColumnFilter",
								floatingFilter: true,
								resizable: true
							}}
							enableCellChangeFlash={true}
							frameworkComponents={
								{
									// handleActionDetailsButtonRender: handleActionDetailsButtonRender
								}
							}
						>
							{user &&
							user.role === ROLES.super_admin &&
							((user.first_name === "Sourish" && user.last_name === "Mukherjee") ||
								(user.first_name === "Anupam" && user.last_name === "Biswas") ||
								(user.first_name === "Rahul" && user.last_name === "Dutta") ||
								(user.first_name === "Suman" && user.last_name === "Dutta")) ? (
								<AgGridColumn
									field="group_number"
									headerName="Group Number"
									floatingFilterComponentParams={{ suppressFilterButton: true }}
									// suppressSizeToFit
									filter={false}
								></AgGridColumn>
							) : null}
							<AgGridColumn
								field="store_name"
								headerName="Store Name"
								// suppressSizeToFit
								// onCellClicked={handleViewcustomersDetails}
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer" };
								}}
							></AgGridColumn>
							{user?.is_customer_name_visibility ? (
								<>
									<AgGridColumn
										field="store_number"
										headerName="Store Number"
										// suppressSizeToFit
										// onCellClicked={handleViewcustomersDetails}
										cellStyle={() => {
											return { textAlign: "left", cursor: "pointer" };
										}}
										//floatingFilterComponentParams={{ suppressFilterButton: true }}
										// floatingFilterComponentParams={{
										// 	suppressFilterButton: true
										// }}
									></AgGridColumn>
									<AgGridColumn
										field="group_number"
										headerName="Group Number"
										// suppressSizeToFit
										//floatingFilterComponentParams={{ suppressFilterButton: true }}
										// onCellClicked={handleViewcustomersDetails}
										cellStyle={() => {
											return { textAlign: "left", cursor: "pointer" };
										}}
									></AgGridColumn>
								</>
							) : null}
							<AgGridColumn
								field="contact_phone_number"
								headerName="Contact Number"
								suppressSizeToFit
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer" };
								}}
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
							></AgGridColumn>
							{user?.is_phone_number_visibility ? (
								<AgGridColumn
									field="city"
									headerName="City"
									// onCellClicked={handleViewcustomersDetails}
									cellStyle={() => {
										return { textAlign: "left", cursor: "pointer" };
									}}
								></AgGridColumn>
							) : null}
							<AgGridColumn
								field="GST_number"
								headerName="GST Number"
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer" };
								}}
							></AgGridColumn>
						</AgGridReact>
					</div>
				</div>
				<div className="custom-pagination" id="custom-pagination">
					<CustomPagination
						pageCount={pageCount}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default StoreGrid;
