import { imageTypes } from "../../@types/image.types";
import { port, url } from "../../config/config";

const generateImageUrl = (image: imageTypes): string => {
	const imageUrl = `${url}:${port}/api/v1/file/${image.filename}`;

	return imageUrl;
};

export default generateImageUrl;
