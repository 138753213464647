import { useState, useEffect, useCallback, useContext, Suspense } from "react";
import { trackPromise } from "react-promise-tracker";
import { Grid } from "@material-ui/core";
import TreeView from "./treeView/TreeView";
import GroupInformation from "./groupInformation/GroupInformation";
import { UIContext } from "../../../../contexts";
import { useLocation } from "react-router-dom";
import { ADMIN_DASHBOARD_HEADER } from "../../../../constants/caption/dashboardHeader";

import SelectAllIcon from "@material-ui/icons/SelectAll";

import "./assignGroups.css";
import { Role } from "../../../../@types/role.types";
import { api } from "../../../../utils/api";
import { useQuery } from "../../../../hooks";
import useRouteParam from "../../../../hooks/useRouteParam";
import { getGroupOwnerList } from "../../../../utils/api/groupOwner/getGroupOwnerDetails";

const AssignGroups = () => {
	const routeParams = useRouteParam("qs");
	const [groupOwnerId, setGroupOwnerId] = useState<string | null>(null);
	const [groups, setGroups] = useState<any[]>([]);
	const [groupInformation, setGroupInformation] = useState<any>(null);
	const [assignmentStatus, setAssignmentStatus] = useState();
	const { setDashboardHeader } = useContext(UIContext);

	const getGroupDetails = useCallback(async () => {
		const response = groupOwnerId
			? await trackPromise(api.group.getAllAssignGroupStore({ user_id: groupOwnerId }))
			: null;
		if (response) {
			console.log("Response", response);
			setGroups(response.groupsAndStores);
		}
	}, [groupOwnerId]);

	console.log("groups", groups);
	const handleAssignGroup = async (index: number) => {
		const tempArr = groups;
		console.log("groups clicked", groups);
		console.log("index_index", tempArr[index]);
		if (tempArr[index].assigned_group_info !== undefined) {
			delete tempArr[index].assigned_group_info;
			for (const store of tempArr[index].unassigned_stores) {
				store.is_assigned = false;
			}
			for (const store of tempArr[index].assigned_stores) {
				store.is_assigned = false;
			}
		} else {
			tempArr[index].assigned_group_info = {};
		}
		tempArr[index].isAssigned = !tempArr[index].isAssigned;
		const payload = {
			user_id: groupOwnerId,
			group_number: tempArr[index].group_number
		};
		const response = await trackPromise(api.permissionManagement.postAssignGroup(payload));
		if (response) {
			setGroups(Object.assign([], groups, ...tempArr));
		}
	};

	const handleAssignStore = async (group_index: number, store_index: number, status: "ASSIGNED" | "UNASSIGNED") => {
		const tempArr = groups;
		const payload = {
			user_id: groupOwnerId,
			group_number: tempArr[group_index].group_number,
			store_number: tempArr[group_index].unassigned_stores[store_index].store_number,
			store_name: tempArr[group_index].unassigned_stores[store_index].store_name
		};
		if (status === "ASSIGNED") {
			tempArr[group_index].assigned_stores[store_index].is_assigned =
				!tempArr[group_index].assigned_stores[store_index].is_assigned;
			payload.store_number = tempArr[group_index].assigned_stores[store_index].store_number;
			payload.store_name = tempArr[group_index].assigned_stores[store_index].store_name;
		} else {
			tempArr[group_index].unassigned_stores[store_index].is_assigned =
				!tempArr[group_index].unassigned_stores[store_index].is_assigned;
		}

		const response = await trackPromise(api.permissionManagement.postAssignStore(payload));
		if (response) {
			setGroups(Object.assign([], groups, ...tempArr));
		}
	};
	const handleSelectProfile = (index: number) => {
		setGroupInformation(groups[index]);
	};
	useEffect(() => {
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.limit_groups);
	}, [setDashboardHeader]);

	// useEffect(() => {
	// 	if (query && query.get("qs")) {
	// 		setGroupOwnerId(query.get("qs"));
	// 	}
	// }, [query]);

	useEffect(() => {
		routeParams && setGroupOwnerId(routeParams as string);
	}, [routeParams]);

	console.log("groupOwnerId", groupOwnerId);

	useEffect(() => {
		getGroupDetails();
	}, [getGroupDetails]);

	console.log("groups from user", groups);

	return (
		<div className="assign-groups">
			<div className="assign-groups-container">
				<Grid container spacing={2} style={{ height: "100%" }}>
					<Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
						<Suspense fallback={<div />}>
							{groupOwnerId ? (
								<TreeView
									groups={groups}
									assignmentStatus={assignmentStatus}
									handleAssignGroup={handleAssignGroup}
									handleAssignStore={handleAssignStore}
									// handleSaveAllGroups={handleSaveAllGroups}
									// handleSaveAllLocations={handleSaveAllLocations}
									handleSelectProfile={handleSelectProfile}
								/>
							) : null}
						</Suspense>
					</Grid>
					<Grid item xl={8} lg={8} md={8} xs={12} sm={12} className="group-info">
						<Suspense fallback={<div />}>
							{groupInformation ? (
								<GroupInformation groupInformation={groupInformation} />
							) : (
								<div className="no-result">
									<span>
										<SelectAllIcon />
									</span>
									Select a group/or location
								</div>
							)}
						</Suspense>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default AssignGroups;
