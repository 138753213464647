import PieChart from "../../../../shared/charts/piechart/PieChart";
import "./DeviceDetails.css";

const DeviceDetails = () => {
	return (
		<div className="main-conatiner">
			<div className="heading-title">
				<p>Total Device Count</p>
				<h2>4000</h2>
			</div>
			<div className="container-body">
				<div className="left-data">
					<div className="stat-data">
						<p>Assigned Device</p>
						<h2>1500</h2>
					</div>
					<div className="stat-data">
						<p>Unassigned Device</p>
						<h2>2500</h2>
					</div>
				</div>
				<div className="chart-container">
					<PieChart />
				</div>
			</div>
		</div>
	);
};

export default DeviceDetails;
