import { useState, Suspense, useCallback, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Button, Tooltip, useMediaQuery } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import { CellValueChangedEvent, ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
// import { api } from "../../../../utils/api";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { GroupBranding } from "../../../../../../@types/group.types";
import { CustomImageUploadDialog } from "../../../../../../@types/components/dialogProps.types";
import { PaginationQuery, PaginationTypes } from "../../../../../../@types/pagination.types";
import { UIContext } from "../../../../../../contexts";
import getLimit from "../../../../../../utils/pagination/getLimit";
import { DialogProps } from "../../../../../../@types/dialogProps.types";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../../../../../utils/api";
import generateImageUrl from "../../../../../../utils/commonFunctions/imageUrl";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../../constants/caption/dashboardHeader";
import getAgGridHeight from "../../../../../../utils/commonFunctions/getAgGridHeight";
import {
	CustomFilter,
	CustomFloatingFilter,
	CustomPagination,
	LazyCustomDialog,
	LazyCustomImageDialog,
	LazyCustomImageUploadDialog
} from "../../../../../shared";
import { IProduct } from "../../../../../../@types/product.types";
import { log } from "console";
import ProductImageModal from "./productImg.modal/ProductImg.modal";
import { deleteProductById } from "../../../../../../utils/api/product/product.management";
const ProductGrid = () => {
	const [products, setProducts] = useState<IProduct[]>([]);
	const [gridHeight, setGridHeight] = useState<string>("400px");
	const [groupBranding, setGroupBranding] = useState<GroupBranding>({
		group_number: 220,
		logo: {},
		favicon: {}
	});
	const [groupsFilterSchema, setGroupsFilterSchema] = useState({
		name: { value: null, type: null },
		group_number: { value: null, type: null },
		email: { value: null, type: null }
	});

	const [imageDialogProps, setImageDialogProps] = useState<any>({
		openDialog: false,
		image: undefined,
		handleCloseCallback: () =>
			setImageDialogProps(
				Object.assign({}, imageDialogProps, {
					openDialog: false
				})
			)
	});
	const [imageUploadProps, setImageUploadProps] = useState<CustomImageUploadDialog>({
		openDialog: false,
		title: "Upload Group Logo",
		imageSrc: groupBranding.logo,
		uploadFields: [
			{
				buttonLabel: "Upload",
				callback: async (event: React.ChangeEvent<HTMLInputElement>) => {
					const { files } = event.target;
					if (files && files[0]) {
						setImageUploadProps((prevImageUploadProps: CustomImageUploadDialog) =>
							Object.assign(
								{},
								{
									...prevImageUploadProps,
									imageSrc: files[0]
								}
							)
						);
					}
				}
			}
		],
		actions: [
			{
				label: "Cancel",
				callback: () => {
					setImageUploadProps(Object.assign({}, imageUploadProps, { openDialog: false }));
					setGroupBranding(
						Object.assign({}, groupBranding, {
							logo: {}
						})
					);
					window.location.reload();
				}
			}
		]
	});
	const [customDialogProps, setCustomDialogProps] = useState<DialogProps>({
		openDialog: false,
		title: "Do you want to delete the image?",
		content: "Make Sure before deletion",
		actions: [
			{
				label: "Cancel",
				name: "cancel",
				callback: () => {
					setCustomDialogProps(Object.assign({}, customDialogProps, { openDialog: false }));
				}
			},
			{
				label: "Yes",
				name: "submit",
				callback: async () => {
					const focusedCell = agGridRef.current.api.getFocusedCell();
					const row = agGridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
					const {
						data: { group_number }
					} = row;
					// await api.groupBranding.deleteGroupBranding(group_number);
					// getGroups();
					setCustomDialogProps(
						Object.assign({}, customDialogProps, {
							openDialog: false
						})
					);
					window.location.reload();
				}
			}
		]
	});
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	// eslint-disable-next-line no-unused-vars
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);
	const [limit, setLimit] = useState<number | null>(null);
	const [pagination, setPagination] = useState<PaginationTypes>({
		currentPage: 1,
		pageCount: undefined
	});
	const { currentPage, pageCount } = pagination;
	const [isRowSelected, setIsRowSelected] = useState(false);
	const navigate = useNavigate();
	const xl = useMediaQuery("(min-width:1600px)");
	const lg = useMediaQuery("(min-width:1200px)");
	const md = useMediaQuery("(min-width:800px)");
	const sm = useMediaQuery("(min-width:600px)");
	const xs = useMediaQuery("(min-width:400px)");
	const _limit = getLimit(xl, lg, md, sm, xs);
	const { setDashboardHeader } = useContext(UIContext);

	const handleAgGridReady = (params: GridReadyEvent) => {
		const { api, columnApi } = params;
		setAgGridAPI(api);
		setAgGridColumnAPI(columnApi);
		api.sizeColumnsToFit();
	};
	const handleFirstDataRendered = (params: any) => null;
	console.log("id", products);

	const handleSeeDetailsClick = useCallback(() => {
		if (agGridRef?.current) {
			const focusedCell = agGridRef.current.api.getFocusedCell();
			const row = agGridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
			const {
				data: { _id }
			} = row;
			navigate(`/settings/edit-product/${_id}`);
		}
	}, [navigate]);

	const handleActionButtonRender = () => {
		return (
			<Button className="ag-grid-cell-button" variant="outlined" onClick={handleSeeDetailsClick}>
				See Details
			</Button>
		);
	};

	const handleViewImageChange = () => {
		console.log(products);
	};
	const handleDeleteChange = async () => {
		try {
			const focusedCell = agGridRef.current.api.getFocusedCell();
			const row = agGridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
			const {
				data: { _id }
			} = row;
			console.log("delete", _id);
			await trackPromise(api.product.deleteProductById(_id));

			// Remove the deleted product from the state
			// eslint-disable-next-line prettier/prettier
			setProducts(prevProducts => prevProducts.filter(product => product._id !== _id));

			setCustomDialogProps(
				Object.assign({}, customDialogProps, {
					openDialog: false
				})
			);
		} catch (error) {
			console.error("Error deleting product:", error);
			// Handle the error, e.g., show a notification to the user
		}
	};

	const handleCloseCustomDialogClick = useCallback(() => {
		setCustomDialogProps(
			Object.assign({}, customDialogProps, {
				openDialog: false
			})
		);
	}, [customDialogProps]);

	const handleUploadChange = (_imageUploadProps: CustomImageUploadDialog) => {
		console.log("_imageUploadProps", _imageUploadProps);
		const focusedCell = agGridRef.current.api.getFocusedCell();
		const row = agGridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
		setImageUploadProps(
			Object.assign({}, _imageUploadProps, {
				openDialog: true,
				uploadFields: [
					{
						buttonLabel: "Upload",
						callback: async (event: React.ChangeEvent<HTMLInputElement>) => {
							const { files } = event.target;
							if (files && files[0]) {
								setImageUploadProps(
									Object.assign({}, _imageUploadProps, {
										imageSrc: files[0],
										openDialog: true,
										actions: [
											..._imageUploadProps.actions,
											{
												label: "Submit",
												callback: async () => {
													const {
														data: { group_number, branding }
													} = row;
													// navigate("/employer");
													const formData: FormData = new FormData();
													formData.append("logo_attachment", files[0]);
													formData.append(
														"group_branding",
														JSON.stringify({
															group_number,
															logo: null,
															favicon: null
														})
													);
													console.log("files[0]", files[0]);
													let groupBranding;
													if (branding?._id) {
														// groupBranding = await api.groupBranding.editGroupBranding(
														// 	formData
														// );
													} else {
														// groupBranding = await api.groupBranding.addGroupBranding(
														// 	formData
														// );
													}
													console.log("groupBranding", groupBranding);
													setImageUploadProps(
														Object.assign({}, _imageUploadProps, { openDialog: false })
													);
													// setGroupBranding(
													// 	Object.assign({}, groupBranding, {
													// 		logo: null
													// 	})
													// );
													setImageUploadProps(
														Object.assign({}, _imageUploadProps, {
															openDialog: false
														})
													);
													// getGroups();
													alert("Successfully Uploaded");
													window.location.reload();
												}
											}
										]
									})
								);
							}
						}
					}
				]
			})
		);
	};

	const handleLogoVisibilityRender = ({
		value,
		imageUploadProps
	}: {
		value: any;
		imageUploadProps: CustomImageUploadDialog;
	}) => {
		console.log("Groups Page", value);
		// alert(value)
		return (
			<div>
				{value === null ? (
					<div style={{ marginLeft: 15 }}>
						<IconButton aria-label="visibility">
							<PublishIcon onClick={() => handleUploadChange(imageUploadProps)} />
						</IconButton>
					</div>
				) : (
					<>
						<Tooltip title="View" enterDelay={500} leaveDelay={200} arrow>
							<IconButton onClick={handleViewImageChange}>
								{/* <VisibilityIcon /> */}
								<ProductImageModal
									product_image={value}
									product_name={""}
									product_area={""}
									description={""}
									diagnosis_id={""}
									product_category={""}
									arc_type={""}
									pain_point=""
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete" enterDelay={500} leaveDelay={200} arrow>
							<IconButton onClick={handleDeleteChange}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</>
				)}
			</div>
		);
	};

	const handleSelectionChanged = useCallback(() => {
		const selectedRow = agGridAPI?.getSelectedRows()[0];
		if (selectedRow) {
			setIsRowSelected(true);
		} else {
			setIsRowSelected(false);
		}
	}, [agGridAPI]);

	const handlePageChange = useCallback(
		(event: React.ChangeEvent<unknown>, page: number) => {
			setPagination(
				Object.assign({}, pagination, {
					currentPage: page
				})
			);
		},
		[pagination]
	);

	const handleCellValueRender = ({ value }: { value: string }) => {
		return <div>{value ? <div>{value}</div> : <div style={{ marginLeft: 15 }}>N/A</div>}</div>;
	};

	const functionToBePassed = () => {
		console.log("I am functionToBePassed");
	};

	const getPaginatedProductList = useCallback(async () => {
		if (typeof limit === "number" && typeof pagination.currentPage === "number") {
			const paginationQuery: PaginationQuery = {
				limit: limit,
				page: pagination.currentPage
			};
			const groupList = await trackPromise(api.product.getPaginatedProductList(paginationQuery));
			setProducts(groupList.products);
			setPagination(
				Object.assign({}, pagination, {
					pageCount: groupList.totalPages
				})
			);
		}
	}, [limit, pagination.currentPage]);

	useEffect(() => {
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.products);
		// getGroups();
	}, [setDashboardHeader]);

	useEffect(() => {
		const _gridHeight = getAgGridHeight(products.length);
		setGridHeight(_gridHeight);
	}, [products.length]);

	useEffect(() => {
		if (_limit >= 0) {
			setLimit(_limit);
			getPaginatedProductList();
		}
	}, [_limit, getPaginatedProductList]);

	// console.log("imgSrc", imageUploadProps);

	return (
		<div className="group-home-page">
			<Suspense fallback={<div />}>
				<LazyCustomImageDialog dialogProps={imageDialogProps} />
			</Suspense>
			<Suspense fallback={<div />}>
				<LazyCustomDialog dialogProps={customDialogProps} handleClose={handleCloseCustomDialogClick} />
			</Suspense>
			<Suspense fallback={<div />}>
				<LazyCustomImageUploadDialog dialogProps={imageUploadProps} />
			</Suspense>
			<div className="group-home-pg-grid-container">
				<div className="ag-theme-alpine" style={{ height: gridHeight, width: "100%" }}>
					<AgGridReact
						ref={agGridRef}
						rowData={products}
						onGridReady={handleAgGridReady}
						onFirstDataRendered={handleFirstDataRendered}
						rowSelection={"Single"}
						animateRows
						defaultColDef={{
							filter: "agTextColumnFilter",
							floatingFilter: true,
							resizable: true
						}}
						onSelectionChanged={handleSelectionChanged}
						frameworkComponents={{
							handleActionButtonRender: handleActionButtonRender,
							handleLogoVisibilityRender: handleLogoVisibilityRender,
							handleCellValueRender: handleCellValueRender,
							customFloatingFilter: CustomFloatingFilter,
							customFilter: CustomFilter,
							schema: groupsFilterSchema,
							functionToBePassed: functionToBePassed,
							setPayloadSchema: setGroupsFilterSchema,
							setParentSchema: setProducts
						}}
						onCellValueChanged={(params: CellValueChangedEvent) => {
							params.api.refreshCells({
								force: true,
								columns: ["branding"]
							});
						}}
						enableCellChangeFlash={true}
					>
						<AgGridColumn
							field="product_name"
							headerName="Product Name"
							// floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleCellValueRender"
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
							onCellClicked={handleSeeDetailsClick}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="product_area"
							headerName="Product Area"
							// floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleCellValueRender"
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
							onCellClicked={handleSeeDetailsClick}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="product_category"
							headerName="Category"
							floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleCellValueRender"
							onCellClicked={handleSeeDetailsClick}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="product_image"
							headerName="Product Image"
							cellRenderer="handleLogoVisibilityRender"
							filter={false}
							cellRendererParams={{ imageUploadProps }}
						></AgGridColumn>
						<AgGridColumn
							field="arc_type"
							headerName="Arc Type"
							filter={false}
							cellRendererParams={{ imageUploadProps }}
						></AgGridColumn>
						<AgGridColumn
							field="action"
							headerName="Action"
							cellRenderer="handleActionButtonRender"
							filter={false}
						></AgGridColumn>
					</AgGridReact>
				</div>
			</div>
			<div className="custom-pagination" id="custom-pagination">
				<CustomPagination pageCount={pageCount} currentPage={currentPage} handlePageChange={handlePageChange} />
			</div>
		</div>
	);
};

export default ProductGrid;
