import React from "react";
import { Doughnut } from "react-chartjs-2";

const DonutChart: React.FC = () => {
	const data = {
		labels: ["Eligible", "Active"],
		datasets: [
			{
				data: [20, 50],
				backgroundColor: ["#ff809f", "#8038c7"],
				borderColor: ["#FFFFFF", "#FFFFFF"],
				borderWidth: 1
			}
		]
	};

	const options = {
		cutout: "40%", // Adjust this value to control the size of the hole in the middle (donut hole)
		plugins: {
			legend: {
				display: true,
				position: "bottom"
			}
		}
	};

	return <Doughnut data={data} options={options as any} />;
};

export default DonutChart;
