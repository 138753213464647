import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { IProduct } from "../../../../../../../@types/product.types";
import empty_box from "../../../../../../../assets/empty_box.png";
import "./product.modal.css";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "40%"
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 4),
			zIndex: 50
		}
	})
);

export default function ProductImageModal({ product_image }: IProduct) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<div onClick={handleOpen}>
				<VisibilityIcon />
			</div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={open}>
					<div className={classes.paper}>
						{product_image ? (
							<>
								<img className="productImage" src={product_image} alt="" />
							</>
						) : (
							<>
								<img className="productImage" src={empty_box} alt="" />
								<div className="productName">
									<p>no image available</p>
								</div>
							</>
						)}
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
