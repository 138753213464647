import { ICard } from "../../../../@types/card.interface";
import CardWithStat from "../../../shared/cards/cardWithStat/CardWithStat";
import DevicesIcon from "@material-ui/icons/Devices";
import BusinessIcon from "@material-ui/icons/Business";
import StoreIcon from "@material-ui/icons/Store";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import "./SuperAdminDashboard.css";
import { useContext, useEffect, useState } from "react";
import { UIContext } from "../../../../contexts";
import { ADMIN_DASHBOARD_HEADER } from "../../../../constants/caption/dashboardHeader";
import DeviceDetails from "./deviceDetails/DeviceDetails";
import ScanDetails from "./scanDetails/ScanDetails";

import "./SuperAdminDashboard.css";

type LegendIndicator = {
	labelName: string;
	colorIndicator: string;
};

const SuperAdminDashboard = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const vebEnrollmentData = {
		labels: ["Newtown", "Ballygaunj", "Garia", "Saltlake", "Shyamnagar", "Bally", "Howrah"],
		datasets: [
			{
				// label: ["HP", "CC", "GSTD", "GLTD 1", "GLTD 2", "HOO", "KSOP", "GLI", "CCE"],

				data: [400, 500, 800, 200, 90, 200, 30, 700, 100, 50, 30], // Replace these values with your actual data
				backgroundColor: [
					"#F08080",
					"#F28C87",
					"#F59D92",
					"#F6A296",
					"#F8AD9D",
					"#FAB9A4",
					"#FBC4AB",
					"#FDCFB2",
					"#FFDAB9"
				],
				borderColor: [
					"#F08080",
					"#F28C87",
					"#F59D92",
					"#F6A296",
					"#F8AD9D",
					"#FAB9A4",
					"#FBC4AB",
					"#FDCFB2",
					"#FFDAB9"
				],
				borderWidth: 1
			}
		]
	};
	const vebEnrollmentOptions = {
		scales: {
			x: {
				type: "category",
				labels: [
					"Newtown",
					"Ballygaunj",
					"Garia",
					"Saltlake",
					"Shyamnagar",
					"Bally",
					"Howrah",
					"Gariahat",
					"Bally"
				],
				ticks: {
					font: {
						size: 12 // Adjust the font size for x-axis labels
					}
				}
			},
			y: {
				beginAtZero: true,
				ticks: {
					font: {
						size: 12 // Adjust the font size for x-axis labels
					}
				}
			}
		},
		plugins: {
			legend: {
				display: false,
				position: "bottom",
				labels: {
					color: "#000" // Set the color you want for the legend labels
				},
				generateLabels: (datasets: any) =>
					datasets.map((dataset: any) => ({
						text: dataset.label,
						fillStyle: dataset.backgroundColor,
						hidden: dataset.hidden,
						lineCap: "round",
						lineDash: [],
						lineDashOffset: 0,
						lineJoin: "round",
						lineWidth: 1,
						strokeStyle: dataset.borderColor,
						pointStyle: "circle",
						datasetIndex: dataset.index
					}))
			}
		}
	};
	useEffect(() => {
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.dashBoard);
		// getGroups();
	}, [setDashboardHeader]);
	return (
		<div>
			<div className="card-list">
				{/* {cardData.map((data, key) => {
					return <CardWithStat key={key} data={data.data} title={data.title} icon={data.icon} />;
				})} */}
				<DeviceDetails />
				<ScanDetails
					cardUIProps={{
						cardColor: "#fff9f5",
						cardTitle: "Today Total Scan Reports",
						overallTotal: "60000",
						marginTopText: "0.7rem"
					}}
					chartProps={{
						data: vebEnrollmentData,
						options: vebEnrollmentOptions
					}}
					// chart={barChart}
					key={0}
				/>
			</div>
		</div>
	);
};

export default SuperAdminDashboard;
