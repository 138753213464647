/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useRef, Suspense, useCallback, useContext, KeyboardEvent } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import {
	TextField,
	Checkbox,
	CheckboxProps,
	withStyles,
	Paper,
	Grid,
	Button,
	Select,
	MenuItem,
	TextareaAutosize
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import "date-fns";
import { green } from "@material-ui/core/colors";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import EventIcon from "@material-ui/icons/Event";
import { AuthContext, UIContext } from "../../../../../../contexts";
import initCapitalize from "../../../../../../utils/commonFunctions/initCapitalize";
import ROLES from "../../../../../../constants/roles";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../../constants/caption/dashboardHeader";
import { DynamicForm, DynamicFormField } from "../../../../../../@types/dynamicForm.types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { IFootcareDevice } from "../../../../../../@types/device.interface";
import { api } from "../../../../../../utils/api";

const GreenCheckbox = withStyles({
	root: {
		color: green[400],
		"&$checked": {
			color: green[600]
		}
	},
	checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const AddDevice = ({ mode }: { mode: string }) => {
	const { user } = useContext(AuthContext); // Extracting logged in user from central storage.
	const navigate = useNavigate();
	const { device_id } = useParams();
	const [userForm, setUserForm] = useState<DynamicForm>();
	const [deviceDetails, setDeviceDetails] = useState<IFootcareDevice>({
		device_id: "",
		is_assigned: false,
		is_active: false,
		group_number: null,
		store_number: null,
		note: ""
	});

	const handleDeviceDetailsChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			console.log(name);
			setDeviceDetails(Object.assign({}, deviceDetails, { [name]: value }));
		},
		[deviceDetails]
	);

	const handleEnableCheckbox = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, checked } = event.target;
			setDeviceDetails(Object.assign({}, deviceDetails, { [name]: checked }));
		},
		[deviceDetails]
	);

	const handleAddDevice = async () => {
		const response = await trackPromise(api.deviceManagement.addDevice(deviceDetails));
		if (response) {
			const path = "/settings/devices";
			navigate(path);
		}
	};
	const handleUpdateDevice = async () => {
		const response = await trackPromise(api.deviceManagement.updateDevice(deviceDetails));
		console.log(response);
		if (response) {
			const path = "/settings/devices";
			navigate(path);
		}
	};

	const handleDeviceClick = () => {
		if (mode === "add") {
			handleAddDevice();
		} else handleUpdateDevice();
	};

	const handleGetDetails = useCallback(async () => {
		const response = await trackPromise(api.deviceManagement.getDevice({ device_id: device_id }));
		setDeviceDetails(response[0]);
	}, [device_id]);

	useEffect(() => {
		const _userForm = Object.assign(
			{},
			{
				"Device Information": [
					{
						label: "Device Id",
						name: "device_id",
						onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleDeviceDetailsChange(event),
						placeholder: "Enter Device Id",
						value: deviceDetails.device_id,
						type: "textfield"
					},
					{
						label: "Device is Assigned",
						name: "is_assigned",
						onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleEnableCheckbox(event),
						placeholder: "Enter Device Id",
						value: deviceDetails.is_assigned,
						type: "checkbox"
					}
				]
			}
		);
		if (!deviceDetails.is_assigned) {
			setUserForm(Object.assign({}, _userForm));
		} else {
			const newDeviceForm = {
				..._userForm,
				"Group & Store Information": [
					{
						label: "Group",
						name: "group_number",
						onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleDeviceDetailsChange(event),
						//placeholder: "Select group",
						value: deviceDetails.group_number,
						type: "select",
						options: [{ name: "Bata", number: 1 }]
					},
					{
						label: "Store",
						name: "store_number",
						onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
							handleDeviceDetailsChange(event);
						},
						//placeholder: "Select group",
						value: deviceDetails.group_number,
						type: "select",
						options: [
							{ name: "Garia", number: 1 },
							{ name: "Newtown", number: 2 }
						]
					}
				],
				"Additional Information": [
					{
						label: "Note",
						name: "note",
						onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleDeviceDetailsChange(event),
						placeholder: "Enter Any Note",
						value: deviceDetails.note,
						type: "textArea",
						controlled: false
					}
				]
			};
			setUserForm(Object.assign({}, newDeviceForm));
		}
	}, [user?.role, handleDeviceDetailsChange, handleEnableCheckbox]);

	useEffect(() => {
		if (mode === "edit") {
			handleGetDetails();
		}
	}, [mode, handleGetDetails]);

	console.log("Create Group Owner", deviceDetails);
	//console.log("Re Enter SSN", reEnterSSN);

	return (
		<div className="create-createGroupOwner" id="create-createGroupOwner">
			{/* <Suspense fallback={<div />}>
				<LazySnackbarAPI snackbarProps={snackbarAPICallProps} />
			</Suspense> */}
			<div className="pf-action-button-container" id="pf-action-button-container">
				<Button
					className="button-green"
					variant="contained"
					disabled={deviceDetails.device_id === ""}
					style={{
						cursor: deviceDetails.device_id === "" ? "not-allowed" : "pointer",
						pointerEvents: "unset"
					}}
				>
					<span className="button-label-with-icon">Add Device</span>
					<span>
						<GroupAddIcon className="button-icon" />
					</span>
				</Button>
			</div>
			<Grid container spacing={1} className="pf-grid-container">
				{userForm &&
					Object.entries(userForm).map(([key, value], index) => {
						return (
							<div key={index}>
								<div className="pf-title">{key}</div>
								<Suspense fallback={<div />}>
									<Paper className="pf-paper-container" elevation={3} variant="outlined">
										{value.map((field: DynamicFormField, index: number) => {
											return (
												<Grid key={index} item xl={12} lg={12} md={12} sm={12} xs={12}>
													<Grid container spacing={1} className="pf-label">
														<Grid
															item
															xs={
																field.name === "is_employer_chat_support" ||
																field.name === "is_member_chat_support"
																	? undefined
																	: 12
															}
															sm={
																field.name === "is_employer_chat_support" ||
																field.name === "is_member_chat_support"
																	? undefined
																	: 12
															}
															md={
																field.name === "is_employer_chat_support" ||
																field.name === "is_member_chat_support"
																	? undefined
																	: 3
															}
															lg={
																field.name === "is_employer_chat_support" ||
																field.name === "is_member_chat_support"
																	? undefined
																	: 3
															}
															xl={
																field.name === "is_employer_chat_support" ||
																field.name === "is_member_chat_support"
																	? undefined
																	: 3
															}
														>
															<div
																className={
																	field.name === "first_name" ||
																	field.name === "last_name" ||
																	field.name === "email" ||
																	field.name === "group_number" ||
																	field.name === "role" ||
																	field.name === "type" ||
																	field.name === "writing_number"
																		? // (createGroupOwner.SSN !== "" &&
																		  // 	field.name === "SSN") ||
																		  // (reEnterSSN !== "" &&
																		  // 	field.name === "re_enter_SSN") ||
																		  // (reEnterSSN !== "" && field.name === "SSN") ||
																		  // (createGroupOwner.SSN !== "" &&
																		  // 	field.name === "re_enter_SSN") ||
																		  // (createGroupOwner.hire_date !== "" &&
																		  // 	field.name === "hire_date") ||
																		  // (createGroupOwner.hire_date !== "" &&
																		  // 	field.name === "date_of_birth")
																		  "pf-label-text required"
																		: "pf-label-text"
																}
																id="pf-label-text"
															>
																{field.label}
															</div>
														</Grid>
														<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
															{field.type === "textfield" ? (
																<TextField
																	className="text-field-input"
																	id="text-field-input"
																	name={field.name}
																	placeholder={field.placeholder}
																	onChange={field.onChange}
																	value={field.value}
																	disabled={
																		field.name === "device_id"
																			? mode === "edit"
																			: false
																	}
																	variant="outlined"
																	style={{
																		//cursor: !isButtonSelected?"not-allowed": "pointer",
																		pointerEvents: "unset",
																		width: "340px",
																		borderRadius: 50
																	}}
																/>
															) : field.type === "select" ? (
																<>
																	<Select
																		id="text-align-options"
																		name={field.name}
																		onChange={field.onChange}
																		//disabled={!isButtonSelected}
																		//disableElevation={!isButtonSelected}
																		style={{
																			//cursor: !isButtonSelected ? "not-allowed" : "pointer",
																			pointerEvents: "unset",
																			minWidth: 180,
																			textAlign: "left",
																			// zIndex: 10000,
																			overflowY: "visible"
																		}}
																		MenuProps={{
																			style: { zIndex: 35960 }
																		}}
																		inputProps={{ "aria-label": "Without label" }}
																	>
																		<MenuItem value="" selected>
																			Select Value
																		</MenuItem>
																		{field?.options?.map((option: any) => (
																			<MenuItem
																				key={option}
																				value={
																					field.name === "group_number"
																						? option?.number
																						: field.name === "store_number"
																						? option?.number
																						: option
																				}
																			>
																				{field.name === "group_number"
																					? option?.name
																					: field.name === "store_number"
																					? option?.name
																					: option}
																			</MenuItem>
																		))}
																	</Select>
																</>
															) : field.type === "date" ? (
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<KeyboardDatePicker
																		className="date-input"
																		id="date-input"
																		inputVariant="outlined"
																		label={field.label}
																		placeholder={field.placeholder}
																		name={field.name}
																		format="MM/dd/yyyy"
																		error={false}
																		value={field.value}
																		onChange={(date: MaterialUiPickersDate) =>
																			field.onChange(date, field.name)
																		}
																		InputProps={{
																			readOnly: true
																		}}
																		InputLabelProps={{
																			shrink: true
																		}}
																		//style={{ width: "340px", borderRadius: 50 }}
																		keyboardIcon={
																			<EventIcon style={{ color: "#7cb342" }} />
																		}
																		KeyboardButtonProps={{
																			"aria-label": "change date"
																		}}
																		// disabled={!isButtonSelected}
																		// disableElevation={!isButtonSelected}
																		style={{
																			// cursor: !isButtonSelected ? "not-allowed" : "pointer",
																			pointerEvents: "unset",
																			width: "340px",
																			borderRadius: 50
																		}}
																	/>
																</MuiPickersUtilsProvider>
															) : field.type === "checkbox" ? (
																<>
																	<div className="check-support" id="check-support">
																		<span>
																			<GreenCheckbox
																				onChange={field.onChange}
																				name={field.name}
																				checked={field.value}
																			/>
																		</span>
																	</div>
																</>
															) : field.type === "textArea" ? (
																<>
																	<TextareaAutosize
																		aria-label="minimum height"
																		name={field.name}
																		onChange={field.onChange}
																		value={field.value}
																		minRows={5}
																		placeholder={field.placeholder}
																		style={{ width: "80%" }}
																	/>
																</>
															) : null}
														</Grid>
													</Grid>
												</Grid>
											);
										})}
									</Paper>
								</Suspense>
							</div>
						);
					})}
			</Grid>
			<div className="pf-action-button-container margin-top-action-button" id="pf-action-button-container">
				<Button
					className="button-green"
					variant="contained"
					onClick={handleDeviceClick}
					disabled={deviceDetails.device_id === ""}
					style={{
						cursor: deviceDetails.device_id === "" ? "not-allowed" : "pointer",
						pointerEvents: "unset"
					}}
				>
					<span className="button-label-with-icon" style={{ textTransform: "capitalize" }}>
						{mode} Device
					</span>
					<span>
						<GroupAddIcon className="button-icon" />
					</span>
				</Button>
			</div>
		</div>
	);
};

export default AddDevice;
