import PieChart from "../../../../shared/charts/piechart/PieChart";
import "./CustomerDetails.css";

const CustomerDetails = () => {
	return (
		<div className="admin-pie-conatiner">
			<div className="admin-heading-title">
				<p>Total Customers Count</p>
				<h2>4000</h2>
			</div>
			<div className="admin-container-body">
				<div className="admin-left-data">
					<div className="admin-stat-data">
						<p>Registered Customer</p>
						<h2>2500</h2>
					</div>
					<div className="admin-stat-data">
						<p>Unregistered Customer</p>
						<h2>1500</h2>
					</div>
				</div>
				<div className="admin-chart-container">
					<PieChart />
				</div>
			</div>
		</div>
	);
};

export default CustomerDetails;
