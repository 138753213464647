/* eslint-disable prettier/prettier */
import React, { useState, useContext, useRef, useCallback, Suspense, useEffect } from "react";
import {
	AppBar,
	CssBaseline,
	Divider,
	Drawer,
	Hidden,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Toolbar,
	Avatar,
	Badge,
	Menu,
	MenuItem,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	makeStyles,
	useTheme,
	withStyles,
	Popover,
	Chip,
	Tooltip
} from "@material-ui/core";
import { Breadcrumbs, Link } from "@material-ui/core";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { DialogProps } from "../../../../@types/dialogProps.types";
// import { api } from "../../../../utils/api";
import { ADMIN_ENV, port, url } from "../../../../config/config";

import { AuthContext, UIContext } from "../../../../contexts";
import DropDown from "../../../shared/dropDown/DropDown";
import { LazyCustomDialog } from "../../../shared";
import SIDEBAR_OPTIONS from "../../../../constants/sidebarOptions";
import { Tab } from "../../../../@types/sidebarOptions.types";
import { bataLogo } from "../../../../assets";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreIcon from "@material-ui/icons/MoreVert";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import "./adminSidebar.css";
import "./adminSidebarScroll.css";
import { PaginationQuery } from "../../../../@types/pagination.types";
import { PdfViewerDialogProps } from "../../../../@types/dialogProps.types";
import ROLES from "../../../../constants/roles";
import useRouteParam from "../../../../hooks/useRouteParam";
import { trackPromise } from "react-promise-tracker";
import ExtractRouteParams from "../../../../utils/commonFunctions/extractRouteParams";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import initCapitalize from "../../../../utils/commonFunctions/initCapitalize";

const drawerWidth = 250;
// eslint-disable-next-line arrow-parens
const useStyles = makeStyles((theme) => ({
	application: {
		display: "flex"
	},
	drawer: {
		// [theme.breakpoints.up("sm")]: {
		// 	width: drawerWidth,
		// 	flexShrink: 0
		// }
	},

	appBar: {
		// [theme.breakpoints.up("sm")]: {
		// width: `calc(100% - ${drawerWidth}px)`,
		// marginLeft: drawerWidth,
		// backgroundColor: "#EEEEEE",
		// color: "black"
		// },
		// backgroundColor: "#EEEEEE",
		// color: "black"
	},
	menuButton: {
		marginRight: theme.spacing(2),
		// [theme.breakpoints.up("sm")]: {
		// display: "",
		backgroundColor: "#EEEEEE",
		color: "black"
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		// width: drawerWidth,
		// backgroundColor: "#3A4652"
	},
	content: {
		flexGrow: 1,
		minHeight: "100vh",
		padding: theme.spacing(3)
	},
	avatarColor: {
		color: "black",
		padding: 40
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex"
		}
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	},
	grow: {
		flexGrow: 1
	}
}));

// eslint-disable-next-line arrow-parens
const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "$ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			// eslint-disable-next-line quotes
			content: '""'
		}
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0
		}
	}
}))(Badge);

const AdminSidebar = (): JSX.Element => {
	const classes = useStyles();
	const querySSN = useRouteParam("qs");
	const queryPlanCode = useRouteParam("pc");
	const theme = useTheme();
	const [isExistGroupNumber, setIsExistGroupNumber] = useState<boolean | null>(null);
	const [drawerWidth, setDrawerWidth] = useState(250);
	const queryGroupNumber = useRouteParam("gn");
	const queryPhone = useRouteParam("qs");
	const { user, setUser } = useContext(AuthContext);
	const [mobileOpen, setMobileOpen] = useState<boolean>(false);
	const [hasFetchedAllNotifications, setHasFetchedAllNotifications] = useState<boolean>(false);
	const [limit, setLimit] = useState<number>();
	const [groupLimit, setGroupLimit] = useState<number | null>(null);
	const [isAccordionSelected, setIsAccordionSelected] = useState<boolean>(true);
	const [anchorEl, setAnchorEl] = useState<(EventTarget & (HTMLLIElement | HTMLButtonElement)) | null>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
	const [popOverAnchorEl, setPopOverAnchorEl] = useState<(EventTarget & SVGSVGElement) | null>(null);
	const [accordionSelectedIndex, setAccordionSelectedIndex] = useState(null);
	const { dashboardHeader, selectedTab, setCustomerUI, customer } = useContext(UIContext);
	const [oldNotifications, setOldNotifications] = useState<any>([]);
	const [newNotifications, setNewNotifications] = useState<any>([]);
	const [notificationCount, setNotificationCount] = useState<number | null>(null);
	const [logoutDialogProps, setLogoutDialogProps] = useState<DialogProps>({
		openDialog: false,
		title: "",
		content: "",
		actions: []
	});
	const [openPdfViewDialogProps, setOpenPdfViewDialogProps] = useState<PdfViewerDialogProps>({
		openDialog: false
	});
	const location = useLocation();
	const [drawerMenuOpen, setDrawerMenuOpen] = useState(true);
	const [showcustomerName, setShowcustomerName] = useState(false);
	const navigate = useNavigate();
	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const isPopOverOpen = Boolean(popOverAnchorEl);
	const id = isPopOverOpen ? "simple-popover" : undefined;
	const [pageNumber, setPageNumber] = useState<number>(0);
	const [skeletonLoad, setSkeletonLoad] = useState<boolean>(false);
	const loading = useRef<boolean>(false);
	const [assignedGroups, setAssignedGroups] = useState([]);
	const [selectedAssignedGroup, setSelectedAssignedGroup] = useState<any>();
	const routeParam = ExtractRouteParams(); // This is to extract the route after ?(Question Mark)

	const scrollToEnd = useCallback(() => {
		if (loading.current === false) {
			setPageNumber(pageNumber + 1);
		}
	}, [pageNumber]);

	const scrollEvent = useCallback(
		(event: React.UIEvent<HTMLDivElement>) => {
			const { scrollHeight, scrollTop, clientHeight } = event.target as HTMLDivElement;

			if (scrollTop + clientHeight > scrollHeight - 5) {
				scrollToEnd();
			}
		},
		[scrollToEnd]
	);

	// const getUser = useCallback(async () => {
	// 	try {
	// 		const response = await trackPromise(api.groupOwner.getGroupOwnerByAuth());
	// 		// console.log("Users", response);
	// 		let _SSN;

	// 		const { SSN } = response;
	// 		// Converting SSN to required type
	// 		const ssn = String(SSN);
	// 		if (ssn) _SSN = ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5);
	// 		if (response) {
	// 			const _user = {
	// 				...response,
	// 				SSN: SSN !== "" || SSN !== undefined ? _SSN : ""
	// 			};
	// 			setUser(Object.assign({}, _user));
	// 		}
	// 	} catch (err) {
	// 		alert("Failed to fetch User!");
	// 	}
	// }, [setUser]);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
		const { currentTarget } = event;
		setAnchorEl(currentTarget);
		const menuArrowElement = document.getElementById("menuArrow");
		if (menuArrowElement) {
			menuArrowElement.style.display = "block";
		}
	};

	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

	const handleLogoutClick = useCallback(() => {
		console.log("==========>clicked")
		localStorage.clear();
		setLogoutDialogProps(
			Object.assign({}, logoutDialogProps, {
				openDialog: true,
				title: "Thank you",
				content: "You have successfully logged out of CuraFoot CRM!",
				actions: [
					{
						label: "Okay",
						callback: () => {
							// window.location.reload();
							navigate("/login");
							setLogoutDialogProps(Object.assign({}, logoutDialogProps, { openDialog: false }));
						}
					}
				]
			})
		);
	}, [logoutDialogProps, navigate]);

	const handleMenuClose = () => {
		setAnchorEl(null);
		const menuArrowElement = document.getElementById("menuArrow");
		if (menuArrowElement) {
			menuArrowElement.style.display = "none";
		}
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		const { currentTarget } = event;
		setMobileMoreAnchorEl(currentTarget);
	};

	const handleGroupChange = useCallback(
		(event: any) => {
			assignedGroups.forEach((group: any) => {
				if (group.group_number === event.target.value) {
					setSelectedAssignedGroup(Object.assign({}, group));
					handleRouteChange(event.target.value as number);
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedAssignedGroup]
	);

	const handleRouteChange = useCallback(
		(groupNumber: number) => {
			const pathname = `/admin?gn=${groupNumber}`;

			navigate(pathname);
			window.location.reload();
		},
		[navigate]
	);

	const handleDrawerToggle = useCallback(() => {
		setMobileOpen(!mobileOpen);
	}, [mobileOpen]);

	const handleDrawerMenuToggle = useCallback(() => {
		setDrawerMenuOpen(!drawerMenuOpen);
		if (drawerMenuOpen === true) {
			setDrawerWidth(0);
		} else {
			setDrawerWidth(250);
		}
	}, [drawerMenuOpen]);

	const handleMyProfileClick = useCallback(() => {
		// console.log("Employer Sidebar Role", user?.role);
		// console.log("Employer Sidebar GN", routeParam);
		let pathname;
		if (user?.role === ROLES.super_admin || user?.role === ROLES.admin) {
			pathname = "/my-profile";
		} else {
			pathname = `/employer/my-profile?${routeParam}`;
		}

		navigate(pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleProfileClick = useCallback(() => {
		handleMyProfileClick();
		setAnchorEl(null);
		handleMobileMenuClose();
	}, [handleMyProfileClick]);

	const handleNameClick = useCallback(() => {
		handleMyProfileClick();
	}, [handleMyProfileClick]);

	const menuId = "primary-search-account-menu";

	const renderMenu = (
		<Popover
			id={menuId}
			open={isMenuOpen}
			anchorEl={anchorEl}
			onClose={handleMenuClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center"
			}}
			style={
				{
					//scrollbar: 5
				}
			}
		>
			<div className="profile-menu">
				<List>
					<ListItem button onClick={handleProfileClick}>
						<ListItemAvatar>
							<Avatar className="list-avatar">
								<PersonIcon /* style={{ color: "#44b700" }} */ />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="My Profile" />
					</ListItem>
					<ListItem button onClick={handleLogoutClick}>
						<ListItemAvatar>
							<Avatar className="list-avatar">
								<ExitToAppIcon /* style={{ color: "#44b700"}} */ />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="Logout" />
					</ListItem>
				</List>
			</div>
		</Popover>
	);

	const mobileMenuId = "primary-search-account-menu-mobile";
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<div className="sec-desk">
				{/* <MenuItem>
					<IconButton aria-label="show 4 new mails">
						<Badge badgeContent={4} color="secondary">
							<MailIcon style={{ color: "#4e4e4e" }} />
						</Badge>
					</IconButton>
					<p>Messages</p>
				</MenuItem> */}
				<a href="/all-notifications">
					<MenuItem>
						<IconButton aria-label="show 11 new notifications">
							<Badge badgeContent={11} color="secondary">
								<NotificationsIcon style={{ color: "#4e4e4e" }} />
							</Badge>
						</IconButton>
						<p>Notifications</p>
					</MenuItem>
				</a>
				<MenuItem onClick={handleProfileMenuOpen}>
					<IconButton
						aria-label="account of current user"
						aria-controls="primary-search-account-menu"
						aria-haspopup="true"
						color="inherit"
					>
						<AccountCircle style={{ color: "#4e4e4e" }} />
					</IconButton>
					<p>Profile</p>
				</MenuItem>
			</div>
		</Menu>
	);

	const handleBreadcrumClick = useCallback(
		async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			event.preventDefault();
			const pathname = "/";
			navigate(pathname);
		},
		[navigate]
	);

	const handleEmployerClick = useCallback(
		async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			event.preventDefault();
			const pathname = `/admin?gn=${queryGroupNumber}`;
			navigate(pathname);
		},
		[navigate, queryGroupNumber]
	);

	const drawer = (
		<div id="employer-dashboard" className="employer-dashboard">
			<div className="avatar-icon-container">
				<IconButton onClick={handleDrawerMenuToggle}>
					<Avatar style={{ width: 24, height: 24, backgroundColor: "#25a18e" }}>
						{/* <CloseIcon style={{ padding: 5 }} /> */}
						<ChevronLeftIcon />
					</Avatar>
				</IconButton>
			</div>
			<div className="sidebar-logo-container">
				<img src={bataLogo} alt="" />
			</div>
			<div className={classes.toolbar} />

			<div className="welcome-container">
				<div className="dashboard-type-text" id="dashboard-type-text">
					{user?.role === ROLES.admin ? (
						initCapitalize(ROLES.admin as string)
					) : (
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" style={{ color: "#e0e0e0" }} />}
							aria-label="breadcrumb"
						>
							<Link className="breadcrumb-link-text" onClick={handleBreadcrumClick}>
								{/* {user?.role !== undefined ? initCapitalize(user?.role as string) : ""} */}
								Super Admin
							</Link>
							<span className="breadcrumb-text" onClick={handleEmployerClick}>
								{initCapitalize(ROLES.admin as string)}
							</span>
						</Breadcrumbs>
					)}
				</div>
				<StyledBadge
					overlap="circular"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					variant="dot"
				>
					<Avatar alt="Remy Sharp" className={classes.avatarColor}>
						<div className="avatar-text">
							{/* {user && user.first_name.charAt(0) + user.last_name.charAt(0)} */}
							SM
						</div>
					</Avatar>
				</StyledBadge>
				<div className="welcome-text" id="welcome-text" onClick={handleNameClick}>
					{user && user.first_name && user.last_name ? user.first_name + " " + user.last_name : null}
				</div>
				{/* <div className="avatar-icon-container">
					<IconButton onClick={handleDrawerMenuToggle}>
						<Avatar>
							<ChevronLeftIcon />
						</Avatar>
					</IconButton>
				</div> */}
			</div>
			<Divider />
			<List>
				{SIDEBAR_OPTIONS["ADMIN"]?.map((tab: Tab, tabIndex: number) => {
					return (
						<>
							{tab.subTabs.length > 0 ? (
								<Accordion
									className="list-item-accordion"
									key={tabIndex}
								// style={{
								// 	display:
								// 		user?.role === ROLES.admin &&
								// 			tab.caption === "User Management"
								// 			? "none"
								// 			: ""
								// }}
								>
									<AccordionSummary
										expandIcon={tab.subTabs.length > 0 ? <ExpandMoreIcon /> : <div />}
										aria-controls="panel1a-content"
										id="panel1a-header"
										className="list-item-accordion-summary"
									>
										<div style={{ marginRight: 10 }}>{tab.icon()}</div>
										<div>{tab.caption}</div>
									</AccordionSummary>
									<AccordionDetails>
										<List>
											{tab.subTabs.length !== 0 &&
												tab.subTabs.map((subTab, subTabIndex) => {
													const { caption, route } = subTab;
													return (
														<ListItem
															button
															key={subTabIndex}
															className="list-item-container"
															onClick={() => navigate(`${route}?gn=${queryGroupNumber}`)}
															style={{
																display:
																	user?.role === ROLES.admin && !user?.is_user_management_permission &&
																		subTab.caption === "User Management"
																		? "none"
																		: "",
																backgroundColor:
																	selectedTab.subTabIndex === subTabIndex &&
																		selectedTab.index === tabIndex
																		? "#85CE36"
																		: "inherit",
																color:
																	selectedTab.subTabIndex === subTabIndex &&
																		selectedTab.index === tabIndex
																		? "#4e4e4e"
																		: "inherit",
																fontWeight:
																	selectedTab.subTabIndex === subTabIndex &&
																		selectedTab.index === tabIndex
																		? "bolder"
																		: "inherit"
															}}
														>
															<ListItemText
																primary={caption}
																className="list-item-text"
																style={{
																	color:
																		selectedTab.subTabIndex === subTabIndex &&
																			selectedTab.index === tabIndex
																			? "#4e4e4e"
																			: "inherit",
																	fontWeight:
																		selectedTab.subTabIndex === subTabIndex &&
																			selectedTab.index === tabIndex
																			? "bolder"
																			: "inherit"
																}}
															/>
														</ListItem>
													);
												})}
										</List>
									</AccordionDetails>
								</Accordion>
							) : (
								<Accordion
									className="list-item-accordion"
									key={tabIndex}
									expanded={false}
									onClick={() => navigate(`${tab.route}?gn=${queryGroupNumber}`)}
								// style={{
								// 	display:
								// 		isEnroller &&
								// 		user?.type === ENROLLER_TYPE.agent &&
								// 		tab.caption === "Enroller Reports"
								// 			? "none"
								// 			: tab.caption === "customer Info Update" &&
								// 			  user &&
								// 			  !(
								// 					(user.first_name === "Sourish" &&
								// 						user.last_name === "Mukherjee") ||
								// 					(user.first_name === "Rahul" &&
								// 						user.last_name === "Dutta") ||
								// 					(user.first_name === "Anupam" &&
								// 						user.last_name === "Biswas") ||
								// 					(user.first_name === "Suman" && user.last_name === "Dutta")
								// 			  ) &&
								// 			  user.role === ROLES.super_admin
								// 			? "none"
								// 			: ""
								// }}
								>
									<AccordionSummary
										expandIcon={tab.subTabs.length > 0 ? <ExpandMoreIcon /> : <div />}
										aria-controls="panel1a-content"
										id="panel1a-header"
										className="list-item-accordion-summary"
									>
										<div
											style={{
												marginRight: 10
											}}
										>
											{tab.icon()}
										</div>
										<div>{tab.caption}</div>
									</AccordionSummary>
								</Accordion>
							)}
						</>
					);
				})}
			</List>
			{/* <div className="avatar-icon-container">
				<IconButton onClick={handleDrawerMenuToggle}>
					<Avatar>
						<ChevronLeftIcon />
					</Avatar>
				</IconButton>
			</div> */}
		</div>
	);

	// const getAssignGroups = useCallback(async () => {
	// 	const params = {
	// 		user_id: user?._id
	// 	};
	// 	const response = await api.assignGroupsAndLocation.getAssignedGroupsAndLocations(params);

	// 	if (response) {
	// 		const _assignedGroups: any[] = [];
	// 		response.groupsAndLocations.forEach((group: any) => {
	// 			if (group.assigned_group_info) {
	// 				_assignedGroups.push(group);
	// 			}
	// 		});

	// 		setAssignedGroups(Object.assign([], _assignedGroups));
	// 	}
	// }, [user?._id]);

	// const getAssignedGroupsCount = useCallback(async () => {
	// 	const assignedGroupsCount = await api.assignGroupsAndLocation.getAssignedGroupsCount();

	// 	setGroupLimit(assignedGroupsCount);
	// }, []);

	// const getAssignedGroups = useCallback(async () => {
	// 	if (typeof groupLimit === "number") {
	// 		const paginationQuery: PaginationQuery = {
	// 			page: 1,
	// 			limit: groupLimit
	// 		};
	// 		const paginatedResult = await api.assignGroupsAndLocation.getAssignedGroups(paginationQuery);
	// 		setAssignedGroups(Object.assign([], paginatedResult.groups));
	// 	}
	// }, [groupLimit]);

	const handleInitialSelectGroup = useCallback((queryGroupNumber: number, assignedGroups: any) => {
		assignedGroups.forEach((group: any) => {
			if (group.group_number === queryGroupNumber) {
				setSelectedAssignedGroup(Object.assign({}, group));
			}
		});
	}, []);

	// console.log("Selected Group", selectedAssignedGroup);

	// useEffect(() => {
	// 	getAssignedGroupsCount();
	// 	getAssignedGroups();
	// }, [getAssignedGroups, getAssignedGroupsCount]);

	useEffect(() => {
		if (queryGroupNumber) {
			setIsExistGroupNumber(true);
		}
	}, [queryGroupNumber, selectedAssignedGroup]);

	// useEffect(() => {
	// 	if (querySSN && !queryPlanCode) {
	// 		setShowcustomerName(true);
	// 	}
	// }, [querySSN, queryPlanCode]);

	useEffect(() => {
		if (queryGroupNumber && assignedGroups) handleInitialSelectGroup(queryGroupNumber as number, assignedGroups);
	}, [assignedGroups, handleInitialSelectGroup, queryGroupNumber]);

	useEffect(() => {
		setLimit(8);
		// getUser();
	}, []);

	const container = window !== undefined ? () => window.document.body : undefined;
	console.log("customer UI", customer);
	return (
		<>
			<div className="employer-sidebar-container" id="employer-sidebar-container">
				{isExistGroupNumber ? (
					<>
						<div className={classes.application}>
							<Suspense fallback={<div />}>
								<LazyCustomDialog dialogProps={logoutDialogProps} />
							</Suspense>
							<CssBaseline />
							<AppBar
								position="fixed"
								className={classes.appBar}
								style={{
									width: `calc(100% - ${drawerWidth}px)`,
									marginLeft: drawerWidth,
									backgroundColor: "#EEEEEE",
									color: "black"
								}}
							>
								<Toolbar className="toolbar">
									{!drawerMenuOpen && (
										<IconButton
											color="inherit"
											aria-label="open drawer"
											edge="start"
											onClick={handleDrawerMenuToggle}
											className={classes.menuButton}
										>
											<MenuIcon />
										</IconButton>
									)}
									<div className="appbar-header" id="appbar-header">
										{dashboardHeader}
									</div>
									{location.search.includes("qs") && customer !== null ? (
										<div className="customer-naming-container" id="customer-naming-container">
											<Chip
												className="chip-container"
												label={
													customer.first_name +
													" " +
													customer.last_name +
													(customer.customer_id ? " - " + customer.customer_id + " " : " ") +
													"(" +
													initCapitalize(customer.gender) +
													")"
												}
											/>
										</div>
									) : null}

									<div className={classes.grow} />
									<div className={classes.sectionDesktop + " sec-desk"}>
										{ADMIN_ENV === "DEV" ? (
											<div className="test-portal-container" id="test-portal-container">
												<Chip label="Test Portal" color="secondary" />
											</div>
										) : null}
										{user?.role === ROLES.super_admin ? (
											<div className="select-group-name-container">
												{selectedAssignedGroup ? (
													<DropDown
														selectedAssignedGroup={selectedAssignedGroup}
														handleGroupChange={handleGroupChange}
														assignedGroups={assignedGroups}
													/>
												) : null}
											</div>
										) : null}
										{/* {ADMIN_ENV === "DEV" || ADMIN_ENV === "LOCAL" ? (
											<Tooltip title="Support" enterDelay={500} leaveDelay={200}>
												<IconButton
													aria-label="help-icon"
													color="inherit"
													onClick={handlePdfViewDialogOpen}
												>
													<HelpOutlineIcon style={{ color: "#4e4e4e" }} />
												</IconButton>
											</Tooltip>
										) : null} */}
										{/* <Tooltip title="Notifications" enterDelay={500} leaveDelay={200}>
											<IconButton aria-label="show new notifications" color="inherit">
												<Badge
													badgeContent={newNotifications ? notificationCount : ""}
													color="secondary"
												>
													<NotificationsIcon
														style={{ color: "#4e4e4e" }}
														onClick={handlePopOverOpen}
													/>
												</Badge>
											</IconButton>
										</Tooltip> */}
										<Tooltip title="Profile" enterDelay={500} leaveDelay={200}>
											<IconButton
												edge="end"
												aria-label="account of current user"
												aria-controls={menuId}
												aria-haspopup="true"
												onClick={handleProfileMenuOpen}
												color="inherit"
											>
												<AccountCircle style={{ color: "#4e4e4e" }} />
											</IconButton>
										</Tooltip>
									</div>
									<div className={classes.sectionMobile}>
										<IconButton
											aria-label="show more"
											aria-controls={mobileMenuId}
											aria-haspopup="true"
											onClick={handleMobileMenuOpen}
											color="inherit"
										>
											<MoreIcon />
										</IconButton>
									</div>
								</Toolbar>
							</AppBar>
							{renderMobileMenu}
							{renderMenu}
							<nav
								className={classes.drawer}
								aria-label="mailbox folders"
								style={{
									[theme.breakpoints.up("md")]: {
										width: drawerWidth,
										flexShrink: 0
									}
								}}
							>
								{/* <Hidden smUp implementation="css">
								<Drawer
									container={container}
									variant="temporary"
									open={mobileOpen}
									onClose={handleDrawerToggle}
									classes={{
										paper: classes.drawerPaper
									}}
									ModalProps={{
										keepMounted: true // Better open performance on mobile.
									}}
								>
									{drawer}
								</Drawer>
							</Hidden> */}
								<div>
									<Drawer
										classes={{
											paper: classes.drawerPaper
										}}
										variant="persistent"
										open={drawerMenuOpen}
										onClose={handleDrawerMenuToggle}
										ModalProps={{
											keepMounted: true // Better open performance on mobile.,
										}}
										style={{
											width: drawerWidth,
											backgroundColor: "#003545"
										}}
									>
										{drawer}
									</Drawer>
								</div>
							</nav>
							<main className={classes.content + " main"}>
								<div className={classes.toolbar} />
								<Outlet />
							</main>
						</div>
					</>
				) : null}
			</div>
		</>
	);
};

export default AdminSidebar;
