import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

import "./AdminTabSection.css";
import ScrollTabPanel from "../../../../shared/tabPanelComponent/ScrollTabPanel";
import DonutChart from "../../../../shared/charts/donutChart/DonutChart";
import a11yScrollableProps from "../../../../../constants/tabPanelProps/ayScrollableProps";
import CardWithStat from "../../../../shared/cards/cardWithStat/CardWithStat";
import PeopleIcon from "@material-ui/icons/People";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SwapVerticalCircleIcon from "@material-ui/icons/SwapVerticalCircle";

// const GreenSwitch = withStyles((theme: Theme) => ({
// 	switchBase: {
// 		color: "white",
// 		"&$checked": {
// 			color: "white"
// 		},
// 		"&$checked + $track": {
// 			backgroundColor: theme.palette.common.white
// 		}
// 	},
// 	checked: {},
// 	track: {
// 		backgroundColor: "white !important"
// 	}
// }))(Switch);
const GreenSwitch = withStyles({
	switchBase: {
		color: "white",
		"&$checked": {
			color: "white"
		},
		"&$checked + $track": {
			backgroundColor: "white"
		}
	},
	checked: {},
	track: {
		backgroundColor: "white"
	}
})(Switch);

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		height: 224
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`
	}
}));

const AdminTabSection = () => {
	const [planCheckedStatus, setPlanCheckedStatus] = useState(false);
	const [value, setValue] = useState(0);
	const classes = useStyles();

	// eslint-disable-next-line arrow-parens
	const handlePlanSwitchClick = (event: any) => {
		const { checked } = event.target;

		setPlanCheckedStatus(checked);
	};

	const handleChange = (event: any, newValue: number) => {
		setValue(newValue);
	};

	return (
		<div className="enrollment-tab-section" id="enrollment-tab-section">
			<div className="specific-enrollment-container" id="specific-enrollment-container">
				<div className="specific-enrollment-header-text">Specific Store Details</div>
			</div>
			<div className="tab-section-main-container" id="tab-section-main-container">
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={value}
					onChange={handleChange}
					style={{
						minWidth: "10rem",
						boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 0px 2.6px",
						borderRadius: "0 0 10px 10px",
						backgroundColor: "#E3F4F4",
						color: "#2E4F4F"
					}}
					aria-label="Vertical tabs example"
					className="glb-tab-panel-custom"
					TabIndicatorProps={{
						style: {
							border: "none",
							backgroundColor: "#47b8b8"
						}
					}}
				>
					<Tab label="Newtown" className="glb-tab-label" {...a11yScrollableProps(0)} />
					<Tab label="Gariahat" className="glb-tab-label" {...a11yScrollableProps(1)} />
					<Tab label="Ballygaunj" className="glb-tab-label" {...a11yScrollableProps(2)} />
					<Tab label="Garia" className="glb-tab-label" {...a11yScrollableProps(3)} />
					<Tab label="Salt lake" className="glb-tab-label" {...a11yScrollableProps(4)} />
					<Tab label="Howrah" className="glb-tab-label" {...a11yScrollableProps(5)} />
					<Tab label="Shyamnagar" className="glb-tab-label" {...a11yScrollableProps(6)} />
					<Tab label="Kolkata" className="glb-tab-label" {...a11yScrollableProps(7)} />
				</Tabs>
				<div className="middle-conatiner">
					<ScrollTabPanel value={value} index={0}>
						<div className="tab-section-information-container">
							<div className="small-card-list">
								<CardWithStat
									data={200}
									backgrondColor="ffffff"
									iconBgColor="#f2e6ff"
									icon={<PeopleIcon style={{ color: "blueviolet" }} />}
									title="Total Customers"
									leftBottomBorderradius="0"
									leftTopBorderradius="15px"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									key={0}
								/>
								<CardWithStat
									data={120}
									title="Reg. Customers"
									backgrondColor="ffffff"
									iconBgColor="#e6f9ff"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="15px"
									icon={<VerifiedUserIcon style={{ color: "#00bfff" }} />}
									key={1}
								/>
								<CardWithStat
									data={80}
									backgrondColor="ffffff"
									iconBgColor="#ffebe6"
									leftBottomBorderradius="15px"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									icon={<ErrorOutlineIcon style={{ color: "#ff3300" }} />}
									title="Unreg. Customers"
									key={2}
								/>
								<CardWithStat
									data={8.9}
									backgrondColor="ffffff"
									iconBgColor="#e6ffe6"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="15px"
									rightTopBorderradius="0"
									icon={<SwapVerticalCircleIcon style={{ color: "#1aff1a" }} />}
									title="Ratio Reg vs Unreg"
									key={3}
								/>
							</div>
							<div className="tab-section-pie-chart" id="tab-section-pie-chart">
								<DonutChart />
							</div>
						</div>
					</ScrollTabPanel>
					<ScrollTabPanel value={value} index={1}>
						<div className="tab-section-information-container">
							<div className="small-card-list">
								<CardWithStat
									data={200}
									backgrondColor="ffffff"
									iconBgColor="#f2e6ff"
									icon={<PeopleIcon style={{ color: "blueviolet" }} />}
									title="Total Customers"
									leftBottomBorderradius="0"
									leftTopBorderradius="15px"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									key={0}
								/>
								<CardWithStat
									data={120}
									title="Reg. Customers"
									backgrondColor="ffffff"
									iconBgColor="#e6f9ff"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="15px"
									icon={<VerifiedUserIcon style={{ color: "#00bfff" }} />}
									key={1}
								/>
								<CardWithStat
									data={80}
									backgrondColor="ffffff"
									iconBgColor="#ffebe6"
									leftBottomBorderradius="15px"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									icon={<ErrorOutlineIcon style={{ color: "#ff3300" }} />}
									title="Unreg. Customers"
									key={2}
								/>
								<CardWithStat
									data={8.9}
									backgrondColor="ffffff"
									iconBgColor="#e6ffe6"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="15px"
									rightTopBorderradius="0"
									icon={<SwapVerticalCircleIcon style={{ color: "#1aff1a" }} />}
									title="Ratio Reg vs Unreg"
									key={3}
								/>
							</div>
							<div className="tab-section-pie-chart" id="tab-section-pie-chart">
								<DonutChart />
							</div>
						</div>
					</ScrollTabPanel>
					<ScrollTabPanel value={value} index={2}>
						<div className="tab-section-information-container">
							<div className="small-card-list">
								<CardWithStat
									data={200}
									backgrondColor="ffffff"
									iconBgColor="#f2e6ff"
									icon={<PeopleIcon style={{ color: "blueviolet" }} />}
									title="Total Customers"
									leftBottomBorderradius="0"
									leftTopBorderradius="15px"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									key={0}
								/>
								<CardWithStat
									data={120}
									title="Reg. Customers"
									backgrondColor="ffffff"
									iconBgColor="#e6f9ff"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="15px"
									icon={<VerifiedUserIcon style={{ color: "#00bfff" }} />}
									key={1}
								/>
								<CardWithStat
									data={80}
									backgrondColor="ffffff"
									iconBgColor="#ffebe6"
									leftBottomBorderradius="15px"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									icon={<ErrorOutlineIcon style={{ color: "#ff3300" }} />}
									title="Unreg. Customers"
									key={2}
								/>
								<CardWithStat
									data={8.9}
									backgrondColor="ffffff"
									iconBgColor="#e6ffe6"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="15px"
									rightTopBorderradius="0"
									icon={<SwapVerticalCircleIcon style={{ color: "#1aff1a" }} />}
									title="Ratio Reg vs Unreg"
									key={3}
								/>
							</div>
							<div className="tab-section-pie-chart" id="tab-section-pie-chart">
								<DonutChart />
							</div>
						</div>
					</ScrollTabPanel>
					<ScrollTabPanel value={value} index={3}>
						<div className="tab-section-information-container">
							<div className="small-card-list">
								<CardWithStat
									data={200}
									backgrondColor="ffffff"
									iconBgColor="#f2e6ff"
									icon={<PeopleIcon style={{ color: "blueviolet" }} />}
									title="Total Customers"
									leftBottomBorderradius="0"
									leftTopBorderradius="15px"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									key={0}
								/>
								<CardWithStat
									data={120}
									title="Reg. Customers"
									backgrondColor="ffffff"
									iconBgColor="#e6f9ff"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="15px"
									icon={<VerifiedUserIcon style={{ color: "#00bfff" }} />}
									key={1}
								/>
								<CardWithStat
									data={80}
									backgrondColor="ffffff"
									iconBgColor="#ffebe6"
									leftBottomBorderradius="15px"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									icon={<ErrorOutlineIcon style={{ color: "#ff3300" }} />}
									title="Unreg. Customers"
									key={2}
								/>
								<CardWithStat
									data={8.9}
									backgrondColor="ffffff"
									iconBgColor="#e6ffe6"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="15px"
									rightTopBorderradius="0"
									icon={<SwapVerticalCircleIcon style={{ color: "#1aff1a" }} />}
									title="Ratio Reg vs Unreg"
									key={3}
								/>
							</div>
							<div className="tab-section-pie-chart" id="tab-section-pie-chart">
								<DonutChart />
							</div>
							<div className="tab-section-area-chart" id="tab-section-area chart"></div>
						</div>
					</ScrollTabPanel>
					<ScrollTabPanel value={value} index={4}>
						<div className="tab-section-information-container">
							<div className="small-card-list">
								<CardWithStat
									data={200}
									backgrondColor="ffffff"
									iconBgColor="#f2e6ff"
									icon={<PeopleIcon style={{ color: "blueviolet" }} />}
									title="Total Customers"
									leftBottomBorderradius="0"
									leftTopBorderradius="15px"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									key={0}
								/>
								<CardWithStat
									data={120}
									title="Reg. Customers"
									backgrondColor="ffffff"
									iconBgColor="#e6f9ff"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="15px"
									icon={<VerifiedUserIcon style={{ color: "#00bfff" }} />}
									key={1}
								/>
								<CardWithStat
									data={80}
									backgrondColor="ffffff"
									iconBgColor="#ffebe6"
									leftBottomBorderradius="15px"
									leftTopBorderradius="0"
									righBottomBorderradius="0"
									rightTopBorderradius="0"
									icon={<ErrorOutlineIcon style={{ color: "#ff3300" }} />}
									title="Unreg. Customers"
									key={2}
								/>
								<CardWithStat
									data={8.9}
									backgrondColor="ffffff"
									iconBgColor="#e6ffe6"
									leftBottomBorderradius="0"
									leftTopBorderradius="0"
									righBottomBorderradius="15px"
									rightTopBorderradius="0"
									icon={<SwapVerticalCircleIcon style={{ color: "#1aff1a" }} />}
									title="Ratio Reg vs Unreg"
									key={3}
								/>
							</div>
							<div className="tab-section-pie-chart" id="tab-section-pie-chart">
								<DonutChart />
							</div>
							<div className="tab-section-area-chart" id="tab-section-area chart"></div>
						</div>
					</ScrollTabPanel>
					<ScrollTabPanel value={value} index={5}>
						<div className="tab-section-information-container">
							<div className="tab-section-information" id="tab-section-information">
								<div className="total-information-container">
									<div className="total-information-label">Total Active Benefits</div>
									<div className="total-information-value">300</div>
								</div>
								<div className="total-information-container">
									<div className="total-information-label">Total Submitted Enrollments</div>
									<div className="total-information-value">200</div>
								</div>
								<div className="total-information-container">
									<div className="total-information-label">Total Waived Enrollments</div>
									<div className="total-information-value">300</div>
								</div>
							</div>
							<div className="tab-section-pie-chart" id="tab-section-pie-chart">
								<DonutChart />
							</div>
							<div className="tab-section-area-chart" id="tab-section-area chart"></div>
						</div>
					</ScrollTabPanel>
					<ScrollTabPanel value={value} index={6}>
						<div className="tab-section-information-container">
							<div className="tab-section-information" id="tab-section-information">
								<div className="total-information-container">
									<div className="total-information-label">Total Active Benefits</div>
									<div className="total-information-value">300</div>
								</div>
								<div className="total-information-container">
									<div className="total-information-label">Total Submitted Enrollments</div>
									<div className="total-information-value">200</div>
								</div>
								<div className="total-information-container">
									<div className="total-information-label">Total Waived Enrollments</div>
									<div className="total-information-value">300</div>
								</div>
							</div>
							<div className="tab-section-pie-chart" id="tab-section-pie-chart">
								<DonutChart />
							</div>
							<div className="tab-section-area-chart" id="tab-section-area chart"></div>
						</div>
					</ScrollTabPanel>
					<ScrollTabPanel value={value} index={7}>
						<div className="tab-section-information-container">
							<div className="tab-section-information" id="tab-section-information">
								<div className="total-information-container">
									<div className="total-information-label">Total Active Benefits</div>
									<div className="total-information-value">300</div>
								</div>
								<div className="total-information-container">
									<div className="total-information-label">Total Submitted Enrollments</div>
									<div className="total-information-value">200</div>
								</div>
								<div className="total-information-container">
									<div className="total-information-label">Total Waived Enrollments</div>
									<div className="total-information-value">300</div>
								</div>
							</div>
							<div className="tab-section-pie-chart" id="tab-section-pie-chart">
								<DonutChart />
							</div>
							<div className="tab-section-area-chart" id="tab-section-area chart"></div>
						</div>
					</ScrollTabPanel>
					<ScrollTabPanel value={value} index={8}>
						<div className="tab-section-information-container">
							<div className="tab-section-information" id="tab-section-information">
								<div className="total-information-container">
									<div className="total-information-label">Total Active Benefits</div>
									<div className="total-information-value">300</div>
								</div>
								<div className="total-information-container">
									<div className="total-information-label">Total Submitted Enrollments</div>
									<div className="total-information-value">200</div>
								</div>
								<div className="total-information-container">
									<div className="total-information-label">Total Waived Enrollments</div>
									<div className="total-information-value">300</div>
								</div>
							</div>
							<div className="tab-section-pie-chart" id="tab-section-pie-chart">
								<DonutChart />
							</div>
							<div className="tab-section-area-chart" id="tab-section-area chart"></div>
						</div>
					</ScrollTabPanel>
				</div>
			</div>
		</div>
	);
};

export default AdminTabSection;
